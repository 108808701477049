import React, { useEffect } from "react";
import styled from "styled-components";
import { motion, useTransform, useSpring } from "framer-motion";
import useFormState from "@hooks/useFormState";
import steps, {
  stepConfig,
  getTotalHeight,
  getHeightByStepAndQuestion,
  getProgressHeightForStep,
  isQuestion
} from "@utils/steps";

const { circleRadius } = stepConfig;

function BriefSteps() {
  const currentStep = useFormState((s) => s.currentStep);
  const currentQuestion = useFormState((s) => s.currentQuestion);
  const updateStep = useFormState((s) => s.updateStep);
  const updateQuestion = useFormState((s) => s.updateQuestion);
  const form = useFormState((s) => s.form);

  const circleDiameter = circleRadius * 2;
  const width = circleDiameter;
  const totalHeight = getTotalHeight(steps) + circleDiameter;
  const offset = circleRadius;
  const strokeWidth = circleRadius / 5;

  const totalPath = `M${circleRadius}, ${offset} L${circleRadius},${
    totalHeight - offset
  }`;

  const pathValue = useSpring(currentStep);

  const pathLength = useTransform(
    pathValue,
    [0, getTotalHeight(steps)],
    [0, 1]
  );

  useEffect(() => {
    const progress = getHeightByStepAndQuestion(currentStep, currentQuestion);

    pathValue.set(progress);
  }, [pathValue, currentStep, currentQuestion]);

  return (
    <Container
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      fill="none"
      viewBox={`0 0 ${width} ${totalHeight}`}
    >
      <motion.path
        d={totalPath}
        stroke="#a7a6ba"
        strokeWidth={strokeWidth}
        // strokeDasharray="6"
      />

      <motion.path
        style={{ pathLength }}
        d={totalPath}
        stroke="#635ec0"
        strokeWidth={strokeWidth}
      />

      {/* <motion.circle
        cx={circleRadius}
        cy={
          circleRadius +
          getHeightByStepAndQuestion(currentStep, currentQuestion)
        }
        r={circleRadius / 1.4}
        fill={"#EEF1FA"}
        stroke={"#635ec0"}
        strokeWidth={3}
      ></motion.circle> */}

      {steps.map((step, index) => {
        const fill = index <= currentStep ? "#635ec0" : "#EEF1FA";
        const stroke = index <= currentStep ? "#635ec0" : "#a7a6ba";

        const progressStep = getTotalHeight(steps.slice(0, index));

        const cy = circleRadius + progressStep;

        return (
          <motion.circle
            key={step.id}
            cx={circleRadius}
            cy={cy}
            r={circleRadius / 1.5}
            fill={fill}
            stroke={stroke}
            strokeWidth={strokeWidth}
            onClick={() => {
              updateStep(index);
              updateQuestion(0);
            }}
          ></motion.circle>
        );
      })}

      {steps.map((step, i) => {
        if (isQuestion(step)) {
          return step.prompts.map((question, j, totalQuestions) => {
            const color = "#635ec0";
            const { formName } = question;
            const responses = form?.[formName] ?? [];

            const hasResponse = Array.isArray(responses)
              ? responses.filter((response) => response.trim()).length > 0
              : responses.length > 0;

            const fill = hasResponse ? color : "#EEF1FA";
            const stroke = hasResponse ? color : "#a7a6ba";

            const progressStep = getTotalHeight(steps.slice(0, i));

            const cy =
              progressStep + circleRadius + getProgressHeightForStep(i, j);

            return (
              <motion.circle
                key={`${i}-${j}`}
                cx={circleRadius}
                cy={cy}
                r={circleRadius / 3}
                fill={fill}
                stroke={stroke}
                onClick={() => {
                  updateStep(i);
                  updateQuestion(j);
                }}
                strokeWidth={strokeWidth * 0.8}
              ></motion.circle>
            );
          });
        }

        return null;
      })}
    </Container>
  );
}

export default BriefSteps;

const Container = styled(motion.svg)`
  circle {
    cursor: pointer;
    filter: none;
    transition: filter 0.2s ease-in;
  }

  circle:hover {
    filter: drop-shadow(0 0 0.2rem #635ec0);
  }
`;

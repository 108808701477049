import React, { useState, useRef } from "react";
import styled from "styled-components";
import useFormState from "@hooks/useFormState";
import Stack from "@components/Stack";

function WelcomeForm() {
  const userDetails = useFormState((s) => s.userDetails);
  const form = useFormState((s) => s.form);
  const updateUserDetails = useFormState((s) => s.updateUserDetails);
  const updateStatus = useFormState((s) => s.updateStatus);
  const [name, setName] = useState(userDetails?.name ?? "");
  const [email, setEmail] = useState(userDetails?.email ?? "");
  const [company, setCompany] = useState(userDetails?.company ?? "");
  const [briefName, setBriefName] = useState(userDetails?.briefName ?? "");

  const inputRef = useRef<HTMLInputElement | null>(null);

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    updateUserDetails({
      name,
      email,
      company,
      briefName
    });

    updateStatus("form");
  }

  return (
    <FormContainer type="column" gap={1} stretchColumns onSubmit={handleSubmit}>
      <InputGroup type="column" gap={0.5} stretchColumns>
        <Label htmlFor="name">Name</Label>
        <Input
          name="name"
          ref={inputRef}
          id="name"
          type="text"
          value={name}
          maxLength={1000}
          onChange={(e) => setName(e.target.value)}
          required
        />
      </InputGroup>
      <InputGroup type="column" gap={0.5} stretchColumns>
        <Label htmlFor="email">Email</Label>
        <Input
          name="email"
          id="email"
          type="email"
          value={email}
          maxLength={1000}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
      </InputGroup>
      <InputGroup type="column" gap={0.5} stretchColumns>
        <Label htmlFor="company">Company</Label>
        <Input
          name="company"
          id="company"
          type="text"
          value={company}
          maxLength={1000}
          onChange={(e) => setCompany(e.target.value)}
          required
        />
      </InputGroup>
      <InputGroup type="column" gap={0.5} stretchColumns>
        <Label htmlFor="briefName">Brief Name</Label>
        <Input
          name="briefName"
          id="briefName"
          type="text"
          value={briefName}
          maxLength={1000}
          onChange={(e) => setBriefName(e.target.value)}
          required
        />
      </InputGroup>
      <Button type="submit">
        {userDetails.name && userDetails.email && userDetails.company
          ? "Continue"
          : "Begin Brief"}
      </Button>

      {/* <SmallText
        onClick={() => {
          navigate("/login");
        }}
      >
        Already have an account? <span>Sign in.</span>
      </SmallText> */}
    </FormContainer>
  );
}

export default WelcomeForm;

const InputGroup = styled(Stack)``;

const Label = styled.label`
  font-weight: bold;
  font-size: 1.6rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #635ec0;
`;

const Input = styled.input`
  appearance: none;
  background: white;
  font-family: "Catamaran", sans-serif;
  font-size: clamp(1.6rem, 5vw, 2rem);
  line-height: 1;
  color: #635ec0;
  border: 2px solid rgba(99, 94, 192, 0.2);
  border-radius: 8px;
  padding: 0.75rem 1.5rem;
  transition: border 300ms ease;

  :active,
  :focus {
    border: 2px solid rgba(99, 94, 192, 1);
  }

  :disabled {
    filter: grayscale(1);

    :active,
    :focus {
      border: 2px solid rgba(99, 94, 192, 0.24);
    }
  }
`;

const Button = styled.button`
  margin-top: 1rem;
  background: #635ec0;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.6rem;
  letter-spacing: 1px;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  transition: background 300ms ease;

  :hover,
  :active {
    background: #4b45b1;
  }

  :disabled {
    filter: grayscale(0.7);
    cursor: not-allowed;
  }
`;

const FormContainer = styled.form<{
  type?: "row" | "column";
  gap?: number;
  center?: boolean;
  stretchColumns?: boolean;
  stretchRows?: boolean;
}>`
  display: grid;
  grid-auto-flow: ${(props) => (props.type === "column" ? "row" : "column")};
  grid-gap: ${(props) => `${props.gap ?? 0}rem`};
  justify-content: flex-start;
  align-items: center;
  position: relative;

  ${(p) =>
    p.center === true &&
    `
    justify-content: center;
  `}

  ${(p) =>
    p.stretchColumns === true &&
    `
    grid-template-columns: minmax(0, 1fr);
  `}

  ${(p) =>
    p.stretchRows === true &&
    `
    grid-template-rows: minmax(0, 1fr);
  `}
`;

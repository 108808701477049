import React, { useEffect, memo } from "react";
import styled, { keyframes } from "styled-components";
import { motion, useSpring, useTransform, useMotionValue } from "framer-motion";
import useFormState from "@hooks/useFormState";
import steps, {
  getTotalHeight,
  getHeightByStepAndQuestion
} from "@utils/steps";
import GlebOrb from "./GlebOrb";

const initialRotate = 200;
const rotateDeg = 40;
const springConfig = {
  stiffness: 15,
  damping: 15,
  bounce: 0.5
};

function DecorativeBackground() {
  const x = useSpring(initialRotate, springConfig);
  const y = useSpring(initialRotate, springConfig);

  const rotateX = useTransform(
    y,
    [0, initialRotate * 2],
    [rotateDeg, -rotateDeg]
  );
  const rotateY = useTransform(
    x,
    [0, initialRotate * 2],
    [-rotateDeg, rotateDeg]
  );

  const areaRef = React.useRef<HTMLDivElement>(null);

  function handleMouse(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    if (areaRef.current) {
      // using a ref because event.target will switch to the values of the child div when you hover over it
      const rect = areaRef.current.getBoundingClientRect();
      // calculating the x and y position within the element
      x.set(event.clientX - rect.left);
      y.set(event.clientY - rect.top);
    }
  }

  function handleMouseExit() {
    x.set(initialRotate);
    y.set(initialRotate);
  }

  return (
    <Container
      ref={areaRef}
      // onMouseMove={handleMouse}
      // onMouseLeave={handleMouseExit}
    >
      <OrbContainer style={{ rotateX, rotateY }}>
        <GlebOrb />
      </OrbContainer>
    </Container>
  );
}

export default memo(DecorativeBackground);

const Container = styled(motion.div)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
  height: 100%;
`;

const OrbContainer = styled(motion.div)`
  width: 100%;
  height: 100%;

  svg {
    height: 100%;
    overflow: visible;
    transform-origin: center center !important;
  }
`;

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { getTime } from "@utils";
import Stack from "@components/Stack";
import useFormState from "@hooks/useFormState";
import Textarea from "react-textarea-autosize";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { DotsHorizontalRounded } from "@styled-icons/boxicons-regular/DotsHorizontalRounded";
// @ts-ignore
import hash from "string-hash";
// @ts-ignore
import color from "tinycolor2";

function UserMessage({
  text,
  handleDelete
}: {
  text: string;
  handleDelete: () => void;
}) {
  const userDetails = useFormState((s) => s.userDetails);

  function handleEdit() {
    alert("editing");
  }

  return (
    <MessageContainer
      type="column"
      gap={0.5}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { delay: 0.5 } }}
      exit={{ opacity: 0 }}
      layout
    >
      <UserContainer
        type="row"
        gap={1}
        style={{ gridTemplateColumns: "auto auto 1fr" }}
      >
        <Avatar name={userDetails.name} />
        <Username>{userDetails.name}</Username>
        <MenuContainer>
          <DropdownMenu.Root>
            <Trigger>
              <MenuIcon />
            </Trigger>

            <StyledContent>
              <DropdownItem
                handleSelect={handleEdit}
                keyboardKey="e"
                text="Edit"
              />
              <DropdownItem
                handleSelect={handleDelete}
                keyboardKey="d"
                text="Delete"
              />
              <StyledArrow />
            </StyledContent>
          </DropdownMenu.Root>
        </MenuContainer>
      </UserContainer>

      <MessageText>{text}</MessageText>
    </MessageContainer>
  );
}

function DropdownItem({
  handleSelect,
  keyboardKey,
  text
}: {
  handleSelect: () => void;
  keyboardKey: string;
  text: string;
}) {
  useEffect(() => {
    async function handlePress(event: KeyboardEvent) {
      if (event.key === keyboardKey) {
        handleSelect();
      }
    }

    document.addEventListener("keydown", handlePress);

    return () => {
      document.removeEventListener("keydown", handlePress);
    };
  }, []);

  return (
    <StyledItem onSelect={handleSelect}>
      <ItemName>{text}</ItemName>
      <ItemKey>{keyboardKey}</ItemKey>
    </StyledItem>
  );
}

const Trigger = styled(DropdownMenu.Trigger)`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 2rem;
`;

const MenuContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const MenuIcon = styled(DotsHorizontalRounded)`
  width: 100%;
  height: 100%;
  color: #635ec0;
  display: block;
`;

const StyledContent = styled(DropdownMenu.Content)`
  min-width: 15rem;
  background-color: white;
  border-radius: 4px;
  padding: 0.5rem;
  box-shadow: 0px 5px 15px -5px hsla(206, 22%, 7%, 0.15);
`;

const StyledItem = styled(DropdownMenu.Item)`
  font-size: 1.55rem;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: default;
  display: flex;
  justify-content: space-between;
  align-items: center;

  :focus {
    outline: none;
    background-color: #635ec0;
    color: white;
  }

  /* :hover kbd,
  :focus kbd {
    background: white;
    color: #635ec0;
  } */
`;

const ItemName = styled.span``;

const ItemKey = styled.kbd`
  color: white;
  background: #635ec0;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-size: 1.35rem;
  text-transform: uppercase;
`;

const StyledArrow = styled(DropdownMenu.Arrow)`
  fill: white;
`;

export default UserMessage;

export function TypingIndicator() {
  const userDetails = useFormState((s) => s.userDetails);
  return (
    <MessageContainer
      type="column"
      gap={0.5}
      initial={{ opacity: 0, x: -10 }}
      animate={{ opacity: 1, x: 0, transition: { delay: 0.5 } }}
      exit={{ opacity: 0, x: -10 }}
    >
      <UserContainer type="row" gap={0} layout>
        <Avatar name={userDetails.name} />
        <Username style={{ marginLeft: "1rem" }} layout>
          {userDetails.name} <span>is typing...</span>
        </Username>
      </UserContainer>
    </MessageContainer>
  );
}

export function Avatar({ name, size = 4.5 }: { name: string; size?: number }) {
  const hashed = hash(name);
  const c = color({ h: hashed % 360, s: 0.95, l: 0.5 });
  const c1 = c.toHexString();
  const c2 = c.triad()[1].toHexString();

  return (
    <AvatarContainer
      style={{
        width: `${size}rem`,
        height: `${size}rem`,
        fontSize: `${size / 2.2}rem`
      }}
    >
      <svg
        role="img"
        aria-label={name}
        viewBox="0 0 40 40"
        style={{ width: "100%", height: "100%" }}
      >
        <defs>
          <linearGradient x1="0%" y1="0%" x2="100%" y2="100%" id={name}>
            <stop stopColor={c1} offset="0%" />
            <stop stopColor={c2} offset="100%" />
          </linearGradient>
        </defs>
        <g stroke="none" strokeWidth="1" fill="none">
          <rect fill={`url(#${name})`} x="0" y="0" width="100%" height="100%" />
        </g>
      </svg>
      {name[0].toUpperCase()}
    </AvatarContainer>
  );
}

const AvatarContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 9999px;
  overflow: hidden;
  color: white;
  font-weight: 200;
  font-size: 1.8rem;
  z-index: 1;

  svg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
`;

const UserLetter = styled.span`
  position: absolute;
  font-family: system-ui, sans-serif;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-weight: 200;
  font-size: 14px;
  z-index: 2;
`;

const MessageContainer = styled(Stack)`
  border-radius: 1rem;
  padding: 1.5rem 2.5rem;
  background: rgba(99, 94, 192, 0.1);
  mix-blend-mode: multiply;
  box-shadow: 0 1px 2px rgba(99, 94, 192, 0.15);
  align-items: flex-start;
  align-self: flex-end;
  justify-self: flex-end;
  max-width: 90%;
  transition: box-shadow 300ms ease;

  @media (max-width: 950px) {
    max-width: 100%;
  }
`;

const UserContainer = styled(Stack)`
  justify-content: flex-start;
`;

const UserAvatar = styled.div`
  width: 32px;
  height: 32px;
  background: #635ec0;
  border-radius: 9999px;
  color: white;
  font-weight: 200;
  /* font-size: 1.4rem; */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: system-ui, sans-serif;
`;

const Username = styled(motion.p)`
  color: #635ec0;
  font-size: 1.55rem;
  font-weight: 600;

  span {
    font-weight: 200;
  }
`;

const MessageText = styled(motion.p)`
  color: #132142;
  text-align: left;
  font-size: 1.6rem;
`;

const InterfaceInput = styled(Textarea)`
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  appearance: none;
  resize: none;
  color: #132142;
  text-align: left;
  font-size: 1.6rem;
`;

const Time = styled(motion.span)`
  font-size: 1.2rem;
  text-transform: uppercase;
  color: #635ec0;
  opacity: 0.7;

  @media (max-width: 950px) {
    display: none;
  }
`;

const ButtonGroup = styled(Stack)`
  margin-top: 0.5rem;
`;

const Button = styled.button`
  padding: 0.5rem 1rem;
  border-radius: 4px;
  background: none;
  border: 1px solid #132142;
  color: #132142;
`;

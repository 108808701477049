
import React, { useState, useEffect, useRef, useMemo } from "react";
import * as RadioGroup from '@radix-ui/react-radio-group';
import {MultiChoiceInput, FormValues} from "@utils/steps";
import styled from "styled-components";
import {
    useFormContext,
    SubmitErrorHandler,
    SubmitHandler,
  } from "react-hook-form";
import useFormState, { FormState } from "@hooks/useFormState";
import Stack from "@components/Stack";
import Textarea from "react-textarea-autosize";

const StyledRadioGroup = styled(RadioGroup.Root)`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const RadioGroupItem = styled(RadioGroup.Item)`
    background-color: white;
    width: 25px;
    height: 25px;
    border-radius: 100%;
    box-shadow: 0 2px 10px var(--black-a7);
    &:hover {
        background-color: rgba(255, 255, 255, 0.65);
    }
    &:focus {
        box-shadow: 0 0 0 2px black;
    }
`;

const RadioGroupIndicator = styled(RadioGroup.Indicator)`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: relative;
    &::after {
        content: '';
        display: block;
        width: 11px;
        height: 11px;
        border-radius: 50%;
        background-color: #635ec0;
    }
`;

const Label = styled.label`
  margin-left: 10px;
`;

const Container = styled(Stack)`
  width: 100%;
`;

const InterfaceInput = styled(Textarea)`
  background: none;
  resize: none;
  appearance: none;
  outline: none;
  border: none;
  overflow: hidden;
  font-size: clamp(1.6rem, 5vw, 1.95rem);
  color: #635ec0;
  padding: 0.75rem 0rem;
  box-shadow: rgb(99, 94, 192, 0.3) 0px 1px;
  transition: box-shadow 0.1s ease-out;

  ::placeholder {
    color: rgba(99, 94, 192, 0.75);
  }

  :focus,
  :active {
    box-shadow: rgba(99, 94, 192, 1) 0px 2px;
  }
`;

export const Radio: React.FC<{ prompt: MultiChoiceInput }> = ({ prompt }) => {
    const form = useFormState((s) => s.form);
    const updateForm = useFormState((s) => s.updateForm);
    const { handleSubmit, setValue, getValues } = useFormContext();
    const singleInputRef = useRef<HTMLTextAreaElement | null>(null);
    const currentFormValue = (form as any)[prompt.formName];
    const textFieldOption = useMemo(() => {
      return prompt.options.find((o) => o.id < 0);
    }, [prompt])
    const [radioSelected, setRadioSelected] = useState(false)

    console.log(`form values: `, getValues(prompt.formName));

    const changedValue = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setValue(prompt.formName, e.currentTarget.value);
        setRadioSelected(true)
        updateForm({
          [prompt.formName]: e.currentTarget.value
        })
    };

    const changedTextField = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setValue(prompt.formName, e.currentTarget.value);
      setRadioSelected(false)
      updateForm({
        [prompt.formName]: e.currentTarget.value
      })
    }

    const onSubmit: SubmitHandler<FormValues> = async (formData, event) => {
        console.log(`form data: `, formData);
        updateForm(formData);
      };
    
      const onError: SubmitErrorHandler<FormValues> = async (errors, event) => {
        console.log({ errors });
      };
    
      useEffect(() => {
        function handleEnter(event: KeyboardEvent) {
          if (
            event.key === "Enter" &&
            event.shiftKey === false &&
            event.metaKey === false
          ) {
            event.preventDefault();
            event.stopPropagation();
            handleSubmit(onSubmit, onError)();
          }
        }
    
        singleInputRef?.current?.addEventListener("keydown", handleEnter);
    
        return () => {
          singleInputRef?.current?.removeEventListener("keydown", handleEnter);
        };
      }, [singleInputRef, handleSubmit, onSubmit, onError]);

    return (
      <Container
        type="column"
        gap={1}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
      >
        <StyledRadioGroup aria-label="View density" value={currentFormValue}>
            {prompt.options.filter(o => o.id > 0).map((option) => {
              return (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <RadioGroupItem onClick={changedValue} className="RadioGroupItem" value={`${option.value}`} id={`radio_${option.id}`}>
                        <RadioGroupIndicator className="RadioGroupIndicator" />
                    </RadioGroupItem>
                    <Label htmlFor={`radio_${option.id}`}>
                        {option.value}
                    </Label>
                </div>
            )})}
        </StyledRadioGroup>
        {textFieldOption && (
          <InterfaceInput
            autoComplete="off"
            name={prompt.formName}
            placeholder={textFieldOption.placeholder ?? "Type your response here..."}
            maxLength={65535}
            onChange={changedTextField}
            value={currentFormValue && radioSelected ? '' : currentFormValue}
          />
        )}
      </Container>
    );
};
export default Radio;
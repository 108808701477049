import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import useFormState from "@hooks/useFormState";
import BriefStepsSVG from "./SVG";
import Stack from "@components/Stack";
import steps, { getTotalHeight, getProgressHeightForStep } from "@utils/steps";
import { CheckCircle } from "@styled-icons/boxicons-solid/CheckCircle";

function BriefSteps() {
  const form = useFormState((s) => s.form);
  const currentStep = useFormState((s) => s.currentStep);
  const currentQuestion = useFormState((s) => s.currentQuestion);
  const updateStep = useFormState((s) => s.updateStep);
  const updateQuestion = useFormState((s) => s.updateQuestion);

  return (
    <BriefStepsContainer type="row" gap={0}>
      <BriefStepsSVG />
      <BriefDetails>
        {steps.map((step, index) => {
          const top = getTotalHeight(steps.slice(0, index)) + 12;

          return (
            <BriefStep
              key={step.id}
              style={{
                top,
                cursor: step.type !== "chat" ? "pointer" : "auto"
              }}
              onClick={() => {
                if (step.type !== "chat") {
                  updateStep(index);
                }
              }}
            >
              <StepNumber
                style={{
                  color:
                    currentStep === index
                      ? "var(--primary-purple)"
                      : currentStep > index
                      ? "var(--faded-purple)"
                      : "var(--faded-grey)"
                }}
              >
                {step.step}{" "}
                {step.type === "upload" && form?.files.length > 0 && (
                  <StepFiles>
                    {form.files.length} File
                    {form.files.length === 1 ? "" : "s"}
                  </StepFiles>
                )}
              </StepNumber>
            </BriefStep>
          );
        })}

        {steps.map((step, i) => {
          if (step.type === "chat") {
            return step.prompts.map((question, j) => {
              const progressStep = getTotalHeight(steps.slice(0, i));

              const top = progressStep + getProgressHeightForStep(i, j) + 10;

              const color =
                currentStep === i && currentQuestion === j
                  ? "white"
                  : currentStep > i ||
                    (currentStep === i && currentQuestion > j)
                  ? "var(--faded-purple)"
                  : "var(--faded-grey)";

              const background =
                currentStep === i && currentQuestion === j
                  ? "var(--primary-purple"
                  : "none";

              return (
                <StepDetails
                  key={question.id}
                  onClick={() => {
                    updateQuestion(j);
                    updateStep(i);
                  }}
                  style={{
                    top,
                    color,
                    background
                  }}
                  type="row"
                  gap={1}
                >
                  {/* <StepDetailsNumber>{question.number}</StepDetailsNumber> */}
                  <StepDetailsText>{question.question}</StepDetailsText>

                  {/* <IconContainer><CheckIcon /></IconContainer> */}
                </StepDetails>
              );
            });
          }
          return null;
        })}
      </BriefDetails>
    </BriefStepsContainer>
  );
}

export default BriefSteps;

const BriefStepsContainer = styled(Stack)`
  /* position: sticky;
  top: var(--top-amount); */
  /* margin: 0 auto; */
  width: 100%;

  --primary-purple: rgba(99, 94, 192, 1);
  --faded-purple: rgba(99, 94, 192, 0.5);
  --faded-grey: rgba(113, 119, 131, 0.5);

  svg {
    padding: 1.6rem 0;

    path {
      stroke-linecap: round;
    }
  }

  @media (max-width: 950px) {
    max-width: 100%;
  }
`;

const BriefDetails = styled.div`
  width: 350px;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const BriefStep = styled(motion.div)`
  position: absolute;
  left: 0;
  width: max-content;
  display: flex;
  flex-direction: column;
  background: none;
  border-radius: 8px;
  padding: 1rem 2rem;
`;

const StepNumber = styled.div`
  text-transform: uppercase;
  white-space: nowrap;
  font-size: 14.5px;
  font-weight: bold;
  letter-spacing: 0.75px;
  color: #717783;
  display: flex;
  align-items: center;
`;

const StepFiles = styled.div`
  background: #635ec0;
  color: white;
  border-radius: 9999px;
  padding: 0.25rem 1rem;
  margin-left: 1ch;
  font-size: 12px;
  font-weight: bold;
`;

const StepNumbers = styled.div`
  font-size: 14.5px;
  letter-spacing: 0.75px;
  color: #717783;
`;

const StepDetails = styled(Stack)`
  position: absolute;
  left: 0;
  padding: 1rem 2rem;
  cursor: pointer;
  border-radius: 8px;
  background: none;

  :hover {
    color: var(--primary-purple);
  }
`;

const IconContainer = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(50%, -50%);
  width: 16px;
  height: 16px;
  border-radius: 9999px;
  background: #40e3a7;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CheckIcon = styled(CheckCircle)`
  width: 100%;
  height: 100%;
  color: #40e3a7;
  background: #40e3a7;
  display: block;
`;

const StepDetailsNumber = styled.div`
  font-weight: bold;
  font-size: 14px;
`;

const StepDetailsText = styled.div`
  width: 295px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14.5px;
  letter-spacing: 0.25px;
`;

const StepContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

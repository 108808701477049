import React, { useState, useRef } from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import toast from "react-hot-toast";
import hash from "string-hash";
import useFormState from "@hooks/useFormState";
import { useIntersectionObserver } from "@hooks";
import Stack from "@components/Stack";
import FileRows from "@components/FileRows";
import { summarySteps } from "@utils/steps";
import * as Separator from "@radix-ui/react-separator";
import * as AlertDialog from "@radix-ui/react-alert-dialog";
import Progress from "nprogress";
import { CheckCircle } from "@styled-icons/boxicons-solid/CheckCircle";
import { AlertCircle } from "@styled-icons/evaicons-solid/AlertCircle";

type FormValue = {
  name: string;
  values: Array<string>;
};

function SummaryInterface({
  currentStep,
  handleFocus
}: {
  currentStep: number;
  handleFocus: () => void;
}) {
  const form = useFormState((s) => s.form);
  const updateStep = useFormState((s) => s.updateStep);
  const updateQuestion = useFormState((s) => s.updateQuestion);
  const updateStatus = useFormState((s) => s.updateStatus);
  const userDetails = useFormState((s) => s.userDetails);
  const resetForm = useFormState((s) => s.resetForm);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const hasAnsweredAll = summarySteps.every(({ questions }) => {
    return questions.every(({ formName }) => {
      const currentField = form?.[formName];
      return currentField !== undefined && currentField.length > 0;
    });
  });

  const isDisabled = isSubmitting === true;

  function buildFormData() {
    const formData = new FormData();

    const { files, ...formValues } = form;

    const mappedFormValues: Array<FormValue> = Object.keys(formValues).map(
      (key) => ({
        name: key,
        values: Array.isArray((formValues as any)[key])
          ? (formValues as any)[key]
          : [(formValues as any)[key]]
      })
    );

    const params = new URLSearchParams(location.search);
    const orgId = params.get("orgId");
    // const studyId = params.get("studyId");

    formData.append("name", userDetails.name);
    formData.append("briefName", userDetails.briefName);
    formData.append("company", userDetails.company);
    formData.append("email", userDetails.email);
    if (orgId) {
      formData.append("org-id", orgId);
    }
    // formData.append("studyId", studyId ?? String(hash(userDetails.briefName)));
    formData.append("formValues", JSON.stringify(mappedFormValues));

    for (const file of form.files) {
      formData.append("files[]", file);
    }

    return formData;
  }

  async function handleSubmit(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    event.preventDefault();

    const formData = buildFormData();

    Progress.start();
    setIsSubmitting(true);
    try {
      const response = await fetch("https://dash-api.sympler.co/api/v1/brief", {
        method: "POST",
        body: formData
      });
      if (response.ok === true) {
        resetForm();
        updateStatus("exit");
      }
    } catch (err) {
      console.error(err);
      toast.error("Could not submit brief at this time.");
    } finally {
      Progress.done();
      setIsSubmitting(false);
    }
  }

  return (
    <SummaryInterfaceContainer
      type="column"
      gap={2}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      stretchColumns
    >
      <CredentialGroup type="column" gap={0} stretchColumns>
        <StepGroup type="row" gap={2}>
          <Step>1</Step>

          <Stack type="column" gap={0.5}>
            <StepAnswered className="header">Your Information</StepAnswered>
            <ExtraStepAnswered>Click to edit</ExtraStepAnswered>
          </Stack>
        </StepGroup>

        <Stack type="column" gap={1} stretchColumns>
          <TwoColumnGrouping type="row" gap={1}>
            <ResponseGrouping
              type="column"
              gap={0.5}
              stretchColumns
              onClick={() => updateStatus("welcome")}
              style={{
                gridTemplateColumns: "minmax(0, 1fr)"
              }}
              isMissingResponse={userDetails.name.length === 0}
            >
              <ResponseQuestion>
                Name<span>*</span>
              </ResponseQuestion>

              <QuestionResponse>{userDetails.name}</QuestionResponse>

              {userDetails.name.length === 0 && (
                <ErrorIconContainer>
                  <ErrorIcon />
                </ErrorIconContainer>
              )}
            </ResponseGrouping>
            <ResponseGrouping
              type="column"
              gap={0.5}
              stretchColumns
              onClick={() => updateStatus("welcome")}
              style={{
                gridTemplateColumns: "minmax(0, 1fr)"
              }}
              isMissingResponse={userDetails.email.length === 0}
            >
              <ResponseQuestion>
                Email<span>*</span>
              </ResponseQuestion>

              <QuestionResponse>{userDetails.email}</QuestionResponse>

              {userDetails.email.length === 0 && (
                <ErrorIconContainer>
                  <ErrorIcon />
                </ErrorIconContainer>
              )}
            </ResponseGrouping>
          </TwoColumnGrouping>

          <TwoColumnGrouping type="row" gap={1}>
            <ResponseGrouping
              type="column"
              gap={0.5}
              stretchColumns
              onClick={() => updateStatus("welcome")}
              style={{
                gridTemplateColumns: "minmax(0, 1fr)"
              }}
              isMissingResponse={userDetails.company.length === 0}
            >
              <ResponseQuestion>
                Company<span>*</span>
              </ResponseQuestion>

              <QuestionResponse>{userDetails.company}</QuestionResponse>

              {userDetails.company.length === 0 && (
                <ErrorIconContainer>
                  <ErrorIcon />
                </ErrorIconContainer>
              )}
            </ResponseGrouping>

            <ResponseGrouping
              type="column"
              gap={0.5}
              stretchColumns
              style={{
                gridTemplateColumns: "minmax(0, 1fr)"
              }}
              onClick={() => updateStatus("welcome")}
              isMissingResponse={userDetails.briefName.length === 0}
            >
              <ResponseQuestion>
                Brief Name<span>*</span>
              </ResponseQuestion>

              <QuestionResponse
                style={{
                  fontStyle:
                    userDetails.briefName.length === 0 ? "italic" : "none",
                  color:
                    userDetails.briefName.length === 0 ? "#928ED3" : "#132142"
                }}
              >
                {userDetails?.briefName || "No response"}
              </QuestionResponse>

              {userDetails.briefName.length === 0 && (
                <ErrorIconContainer>
                  <ErrorIcon />
                </ErrorIconContainer>
              )}
            </ResponseGrouping>
          </TwoColumnGrouping>
        </Stack>
      </CredentialGroup>

      <CredentialGroup type="column" gap={1} stretchColumns>
        <StepGroup type="row" gap={2}>
          <Step>2</Step>

          <Stack type="column" gap={0.5}>
            <StepAnswered className="header">Brief Information</StepAnswered>
            <ExtraStepAnswered>Click to edit</ExtraStepAnswered>
          </Stack>
        </StepGroup>

        {summarySteps.map(({ stepNumber, questions }) => {
          return questions.map(
            ({ question, questionNumber, formName, name }) => {
              const response = form[formName];
              const isMissingResponse = Array.isArray(response)
                ? response.filter((text) => text !== "").length === 0
                : response.length === 0;

              return (
                <ResponseGrouping
                  type="column"
                  gap={0.5}
                  stretchColumns
                  key={questionNumber}
                  onClick={() => {
                    updateQuestion(questionNumber - 1);
                    updateStep(0);
                  }}
                  style={{ gridTemplateColumns: "minmax(0, 1fr)" }}
                  isMissingResponse={isMissingResponse}
                >
                  <ResponseQuestion style={{ textAlign: "left" }}>
                    {question}
                  </ResponseQuestion>

                  <QuestionResponse
                    type="column"
                    gap={1}
                    style={{
                      textAlign: "left",
                      fontStyle: isMissingResponse ? "italic" : "none",
                      color: isMissingResponse ? "#928ED3" : "#132142"
                    }}
                  >
                    {isMissingResponse ? (
                      "No response"
                    ) : Array.isArray(response) ? (
                      response
                        .filter((text) => text !== "")
                        .map((text, index) => (
                          <li
                            key={`${text}-${index}`}
                            style={{ listStyle: "inside" }}
                          >
                            {text}
                          </li>
                        ))
                    ) : (
                      <li style={{ listStyle: "inside" }}>{response}</li>
                    )}
                  </QuestionResponse>

                  {isMissingResponse && (
                    <ErrorIconContainer>
                      <ErrorIcon />
                    </ErrorIconContainer>
                  )}
                </ResponseGrouping>
              );
            }
          );
        })}
      </CredentialGroup>

      <CredentialGroup type="column" gap={0} stretchColumns>
        <StepGroup type="row" gap={2}>
          <Step>3</Step>
          <Stack type="column" gap={0.5}>
            <StepAnswered className="header">Background Materials</StepAnswered>
            <ExtraStepAnswered>Click to upload files</ExtraStepAnswered>
          </Stack>
        </StepGroup>

        <ResponseGrouping
          type="column"
          gap={0}
          stretchColumns
          onClick={() => updateStep(1)}
          style={{ gridTemplateColumns: "minmax(0, 1fr)" }}
          isMissingResponse={false}
        >
          <FileRows />
        </ResponseGrouping>
      </CredentialGroup>

      <SubmitButton disabled={isDisabled} onClick={handleSubmit}>
        Submit Brief
      </SubmitButton>

      {isSubmitting && <SubmittingOverlay />}
    </SummaryInterfaceContainer>
  );
}

export default SummaryInterface;

const SummaryInterfaceContainer = styled(Stack)`
  --purple-300: #8c88d1;
  --purple-500: #635ec0;
  --purple-700: #4540a4;

  width: clamp(600px, 65vw, 690px);
  margin: 0 auto;
  overflow: visible;
  align-self: center;

  @media (max-width: 950px) {
    width: 100%;
  }
`;

const StackContainer = styled(Stack)`
  /* background: rgba(255, 255, 255, 0.3);
  box-shadow: 0 1px 2px rgba(99, 94, 192, 0.15);
  mix-blend-mode: multiply; */
  border-radius: 1rem;
  /* padding-bottom: 4rem; */
  position: relative;
  /* padding: 4rem; */

  @media (max-width: 950px) {
    /* padding: 2rem; */
  }
`;

const SummaryContainer = styled(Stack)`
  /* background: #deddf2; */
  border-radius: 8px;
`;

const StepTitle = styled.div`
  text-transform: uppercase;
  font-size: 18.5px;
  font-weight: bold;
  letter-spacing: 0.75px;
  color: #635ec0;
`;

const QuestionTitle = styled.div`
  font-size: 1.95rem;
  color: #132142;
  letter-spacing: 0.25px;
`;

const QuestionText = styled.span`
  font-size: clamp(1.75rem, 5vw, 2rem);
  color: #132142;
  line-height: 1;
`;

const SummaryInfo = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(calc(-115% - 2rem));
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 1.15rem;
  color: white;
  background: #635ec0;
  width: 100%;
  text-align: center;
  padding: 0.5rem 0;
  border-radius: 4px;

  span {
    /* font-weight: bold; */
    /* font-size: 1.2rem; */
  }
`;

const SubmitButton = styled.button<{ disabled: boolean }>`
  background: #635ec0;
  color: white;
  width: 100%;
  text-align: center;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 1.4rem;
  padding: 0.5rem 1rem;
  border-radius: 4px;

  ${(props) =>
    props.disabled &&
    `
    cursor: not-allowed;
    background: #717783;
  `}
`;

const CheckmarkIcon = styled(CheckCircle)`
  width: 24px;
  display: inline-block;
  color: seagreen;
  margin-right: 2ch;
`;

const AlertIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* min-width: 28px; */
`;

const AlertIcon = styled(AlertCircle)`
  width: 24px;
  display: inline-block;
  color: #f97f70;
  margin-right: 2ch;
`;

const Checkbox = styled.input`
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  border-radius: 2px;
  border: 1px solid #635ec0;

  :checked {
    background: #635ec0;
  }
`;

const InstructionText = styled.p`
  flex: 1;
  color: #142132;
  font-size: 1.5rem;
  text-align: left;

  a {
    :hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

const ResponseContainer = styled(Stack)`
  border-radius: 1rem;
  padding: 1rem;
  background: rgba(99, 94, 192, 0.1);
  mix-blend-mode: multiply;
  box-shadow: 0 1px 2px rgba(99, 94, 192, 0.15);
  position: relative;
  justify-self: flex-end;
  /* max-width: 90%; */
  cursor: pointer;
`;

const QuestionContainer = styled(ResponseContainer)`
  justify-self: flex-start;
  background: white;
  cursor: auto;
`;

const QuestionNumber = styled(motion.div)`
  position: absolute;
  top: 2rem;
  left: 0;
  transform: translate(-50%, 0);
  width: 28px;
  height: 28px;
  background: #8c88d1;
  border-radius: 9999px;
  color: white;
  font-size: 1.25rem;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  vertical-align: middle;
`;

const Text = styled.p`
  font-size: 1.45rem;
  letter-spacing: 0.5px;
  color: #132142;

  span {
    font-size: 1.6rem;
    font-family: "Catamaran", sans-serif;
    color: tomato;
  }
`;

const MessageText = styled.span`
  font-size: 2.9rem;
  font-weight: 200;
  color: #132142;
  line-height: 1;
`;

const TopText = styled.h1`
  font-weight: 300;
  line-height: 1;
  color: #635ec0;

  display: flex;
  align-items: center;
`;

const ExtraStepAnswered = styled.span`
  text-transform: uppercase;
  font-size: 14.5px;
  font-weight: bold;
  letter-spacing: 0.75px;
  color: #736ec6;
`;

const StyledSeparator = styled(Separator.Root)`
  width: 100%;
  height: 2px;
  opacity: 0.8;
  background: #635ec0;
  /* margin-top: 2rem; */
`;

const CredentialStep = styled.span`
  font-family: "Catamaran", sans-serif;
  color: #635ec0;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 1.35rem;
`;

const CredentialGroup = styled(Stack)`
  overflow: hidden;
  border-radius: 2rem;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.35);
`;

const StepGroup = styled(Stack)`
  padding: 3rem 2rem;
`;

const StepAnswered = styled.div`
  /* text-transform: uppercase; */
  font-size: 2.4rem;
  font-weight: 200;
  text-align: left;
  position: relative;
  font-size: clamp(2.8rem, 5vw, 3.1rem);
  font-weight: 200;
  color: #132142;
  /* color: #4b45b1; */
  line-height: 1;
  display: flex;
  align-items: center;

  :hover {
    background: none;
  }
`;

const Step = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 1.8rem;
  background: rgba(99, 94, 192, 0.9);
  color: white;
  mix-blend-mode: multiply;
  border-radius: 9999px;
  font-weight: bold;
  width: 4.1rem;
  height: 4.1rem;
  position: relative;
  box-shadow: 0 1px 2px rgba(99, 94, 192, 0.15);
  transition: background 300ms ease, color 300ms ease;
`;

const TwoColumnGrouping = styled(Stack)`
  grid-template-columns: 1fr 1fr;
  color: inherit;

  @media (max-width: 950px) {
    grid-template-columns: minmax(0, 1fr);
    grid-auto-flow: row;
  }
`;

const ResponseGrouping = styled(Stack)<{ isMissingResponse: boolean }>`
  padding: 2rem;
  grid-template-columns: 155px 1fr;
  cursor: pointer;
  color: #635ec0;

  mix-blend-mode: multiply;
  transition: background 300ms ease;
  align-self: stretch;
  border-radius: 1rem;

  :hover {
    background: rgba(255, 255, 255, 0.6);
    color: #4540a4;
  }

  ${(props) =>
    props.isMissingResponse &&
    `
      background: rgba(255, 99, 71, 0.15);


      :hover {
        background:  rgba(255, 99, 71, 0.3);
      }
    `}

  @media (max-width: 950px) {
    grid-template-columns: auto 1fr;
  }
`;

const ResponseQuestion = styled.p`
  font-weight: bold;
  font-size: 1.6rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #635ec0;

  span {
    font-size: 1.4rem;
    font-family: "Catamaran", sans-serif;
    color: tomato;
  }
`;

const QuestionResponse = styled(Stack)`
  white-space: pre-line;
  text-align: left;
  color: #132142;
  overflow: hidden;
`;

const ErrorIconContainer = styled.div`
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translate(0, -50%);
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ErrorIcon = styled(AlertCircle)`
  color: rgba(255, 99, 71, 0.6);
  width: 100%;
  height: 100%;
`;

interface IntersectionGroupProps {
  children: React.ReactNode;
}

function IntersectionGroup({ children }: IntersectionGroupProps) {
  const intersectionRef = useRef(null);
  const [isVisible] = useIntersectionObserver({
    elementRef: intersectionRef,
    threshold: 0.1,
    freezeOnceVisible: false
  });

  return (
    <StudyCardContainer ref={intersectionRef}>
      <AnimatePresence>{isVisible && children}</AnimatePresence>
    </StudyCardContainer>
  );
}

const StudyCardContainer = styled(motion.div)`
  min-width: 0;
`;

const AlertOverlay = styled(AlertDialog.Overlay)`
  background-color: rgba(0, 0, 0, 0.35);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(2px);
`;

const AlertContent = styled(AlertDialog.Content)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 1rem;
  padding: 3rem;
  background: rgba(255, 255, 255, 1);
  mix-blend-mode: multiply;
  box-shadow: 0 1px 2px rgba(99, 94, 192, 0.15);
`;

const AlertTitle = styled(AlertDialog.Title)`
  color: #635ec0;
`;

const AlertDescription = styled(AlertDialog.Description)`
  color: #132142;
  font-size: 1.6rem;
`;

const ButtonGroup = styled(Stack)`
  margin-top: 1rem;
`;

const AlertAction = styled(AlertDialog.Action)`
  color: white;
  width: 100%;
  text-align: center;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 1.4rem;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  background: #635ec0;
  transition: background 300ms ease;

  :hover,
  :active {
    background: #4b45b1;
  }
`;

const AlertCancel = styled(AlertDialog.Cancel)`
  width: 100%;
  text-align: center;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 1.4rem;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  /* color: white;
  background: #afb3bf;
  transition: background 300ms ease;

  :hover,
  :active {
    background: #999eae;
  } */

  color: #635ec0;
  background: white;
  border: 1px solid #635ec0;
`;

const SubmittingOverlay = styled.div`
  position: fixed;
  background: rgba(0, 0, 0, 0.3);
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  backdrop-filter: blur(2px);
`;

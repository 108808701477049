import React, { useEffect } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import toast from "react-hot-toast";
import useFormState from "@hooks/useFormState";
import { useDropzone, FileRejection, FileError } from "react-dropzone";
import Stack from "@components/Stack";
import FileRows from "@components/FileRows";
import steps, { UploadStep } from "@utils/steps";
import { Info } from "@styled-icons/material/Info";
import { Upload } from "@styled-icons/heroicons-solid/Upload";

function UploadInterface({ currentStep }: { currentStep: number }) {
  const form = useFormState((s) => s.form);
  const updateForm = useFormState((s) => s.updateForm);
  const updateStep = useFormState((s) => s.updateStep);

  const current = steps[currentStep] as UploadStep;
  const { message, hint, name } = current;

  function onDropAccepted(files: File[]) {
    if (files.length > 1) {
      toast.success(`Successfully added ${files.length} files`);
    } else {
      const file = files[0];
      toast.success(`Successfully added "${file.name}"`);
    }

    updateForm({ files: [...form.files, ...files] });
  }

  function onDropRejected(files: Array<FileRejection>) {
    if (files.length > 1) {
      toast.error(`Could not add ${files.length} files`);
    } else {
      const file = files[0];
      toast.error(`Could not add file "${file.file.name}"`);
    }
  }

  function handleClick() {
    updateStep(2);
  }

  function duplicateFileValidator(file: File) {
    for (const existingFile of form.files) {
      if (existingFile.name === file.name) {
        return {
          code: "duplicate-file",
          message: "Cannot accept duplicate files"
        };
      }
    }

    return null;
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: [
      // jpeg
      "image/jpeg",
      // png
      "image/png",
      // gif
      "image/gif",
      // mp4
      "video/mp4",
      // avi
      "video/x-msvideo",
      // webm
      "video/webm",
      // mpeg
      "video/mpeg",
      // pdf
      "application/pdf",
      // ppt
      "application/vnd.ms-powerpoint",
      // pptx
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      // doc
      "application/msword",
      // docx
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      // xls
      "application/vnd.ms-excel",
      // xlsx
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      // csv
      "text/csv"
    ],
    onDropAccepted,
    onDropRejected,
    validator: duplicateFileValidator,
    noKeyboard: true,
    maxFiles: 5,
    maxSize: 25000000
  });

  useEffect(() => {
    function handleEnter(event: KeyboardEvent) {
      if (event.key === "Enter") {
        event.preventDefault();
        updateStep(2);
      }
    }

    document.addEventListener("keydown", handleEnter);

    return () => {
      document.removeEventListener("keydown", handleEnter);
    };
  }, [updateStep]);

  return (
    <UploadInterfaceContainer
      type="column"
      gap={3}
      stretchColumns
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Stack type="row" gap={2}>
        <Stack type="column" gap={1}>
          <Text
            style={{
              fontSize: "1.5rem",
              textTransform: "uppercase",
              letterSpacing: "1px",
              color: "#635ec0",
              fontFamily: "Catamaran",
              marginBottom: "-1rem"
            }}
          >
            {name}
          </Text>
          <QuestionText>{message}</QuestionText>

          {/* <Text style={{ fontSize: "1.5rem", color: "#424D68" }}>
            jpeg, png, pdf, ppt, doc, xls, csv
          </Text> */}
        </Stack>
      </Stack>

      <Stack type="column" gap={4} stretchColumns>
        <Thumbnail {...getRootProps()}>
          <input {...getInputProps()} />

          <DragAndDropText>Click or drag files here.</DragAndDropText>
        </Thumbnail>

        <FileRows />
      </Stack>

      <ButtonGroup type="row" gap={1}>
        <Button onClick={handleClick}>Next</Button>

        <Text className="info">
          or press <span>Enter </span>
        </Text>
      </ButtonGroup>
    </UploadInterfaceContainer>
  );
}

export default UploadInterface;

const UploadInterfaceContainer = styled(Stack)`
  width: clamp(600px, 65vw, 690px);
  margin: 0 auto;
  overflow: visible;
  align-self: center;

  .info {
    opacity: 0;
    transition: opacity 0.1s ease-out;
  }

  &:focus,
  &:focus-within {
    .info {
      opacity: 1;
    }
  }

  @media (max-width: 950px) {
    width: 100%;

    .info {
      display: none;
    }
  }
`;

const DragAndDropText = styled(motion.p)`
  color: rgba(99, 94, 192, 1);
  letter-spacing: 0.25px;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  /* margin-left: 1ch; */
`;

const InfoIcon = styled(Info)`
  color: #716cc6;
  width: 18px;
  height: 18px;
  margin-right: 1ch;
  flex-shrink: 0;
  align-self: flex-start;
`;

const DropzoneContainerContainer = styled(motion.div)`
  border-radius: 8px;
  border: 2px dashed rgba(99, 94, 192, 0.25);
  margin: 0 auto;
  width: 75%;
  height: 135px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const DropzoneContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const UploadIcon = styled(Upload)`
  color: rgba(99, 94, 192, 1);
  width: 24px;
`;

const Thumbnail = styled.div`
  width: 100%;
  height: 85px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 2rem;
  border-radius: 8px;
  aspect-ratio: 1 / 1;
  position: relative;
  background: rgba(255, 255, 255, 0.2);
  mix-blend-mode: multiply;
  padding: 1rem 0;
  font-size: 1.5rem;
  letter-spacing: 0.25px;
  color: #635ec0;
  border-radius: 4px;
  /* border: 1px solid #635ec0; */
  border: 3px dashed rgba(99, 94, 192, 0.25);
  box-shadow: 0 1px 2px rgba(99, 94, 192, 0.15);
  cursor: pointer;
  transition: box-shadow 300ms ease;

  :focus,
  :active {
    border: 3px dashed rgba(99, 94, 192, 0.5);
  }

  :hover {
    box-shadow: 0 1px 4px rgba(99, 94, 192, 0.4);
  }
`;

const FileExt = styled.div`
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  background: #635ec0;
  color: white;
  font-size: 1.25rem;
  font-weight: bold;
  text-transform: uppercase;
  padding: 0.25rem 0.75rem;
  border-radius: 4px;
`;

const FileName = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(calc(100% + 1rem), -50%);
  color: #635ec0;
  font-size: 1.4rem;
  font-weight: bold;
  letter-spacing: 0.5px;
`;

const InstructionContainer = styled(Stack)`
  grid-template-columns: 1fr auto;

  @media (max-width: 950px) {
    grid-auto-flow: row;
    grid-template-columns: 1fr;
  }
`;

const SubmitButton = styled.div`
  background: #635ec0;
  color: white;
  width: 100%;
  text-align: center;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 1.4rem;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
`;

const Toast = styled.div`
  background: #635ec0;
  color: white;
  font-size: 1.7rem;
  letter-spacing: 0.5px;
  border-radius: 4px;
  padding: 0.5rem 1rem;
`;

const Text = styled.p`
  font-size: 1.3rem;
  letter-spacing: 0.25px;
  color: #132142;

  span {
    font-weight: 600;
  }
`;

const QuestionText = styled.span`
  font-size: 2.9rem;
  font-weight: 200;
  color: #132142;
  line-height: 1;
`;

const ButtonGroup = styled(Stack)``;

const Button = styled.button`
  background: #635ec0;
  color: white;
  font-size: 1.4rem;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  border-radius: 4px;
  padding: 0.5rem 2rem;
  height: 32px;

  transition: background 300ms ease;

  :hover,
  :active {
    background: #4b45b1;
  }

  /* :focus {
    outline: 3px solid #635ec0;
    outline-offset: 4px;
  } */

  :disabled {
    filter: grayscale(0.7);
  }

  @media (max-width: 950px) {
    font-size: 1.8rem;
    height: 42px;
  }
`;

import React, { useEffect, useRef, useState, useMemo } from "react";
import styled from "styled-components";
import { motion, useAnimation, AnimatePresence } from "framer-motion";
import {
  useFormContext,
  SubmitHandler,
  SubmitErrorHandler
} from "react-hook-form";
import useFormState, { FormState } from "@hooks/useFormState";
import Stack from "@components/Stack";
import { TextInput, Checkbox, Radio } from "@components/BriefInputs";
import steps, { ChatStep, FormValues, isText, isCheckbox, isMultiChoice } from "@utils/steps";
import { useMobileDetect } from "@hooks";
import { ChevronUp } from "@styled-icons/heroicons-solid/ChevronUp";
import { ChevronDown } from "@styled-icons/heroicons-solid/ChevronDown";
 
function ChatInterface({ currentStep }: { currentStep: number }) {
  const form = useFormState((s) => s.form);
  const currentQuestion = useFormState((s) => s.currentQuestion);
  const updateForm = useFormState((s) => s.updateForm);
  const updateQuestion = useFormState((s) => s.updateQuestion);
  const updateStep = useFormState((s) => s.updateStep);

  console.log(`form: `, form);

  const { handleSubmit } = useFormContext();

  const inputRef = useRef<HTMLTextAreaElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const { isMobile } = useMobileDetect();

  const [inputValue, setInputValue] = useState("");

  const step = steps[currentStep] as ChatStep;

  const currentPrompt = step?.prompts?.[currentQuestion as number];
  const currentFormValue = (form as any)[`form-${currentQuestion + 1}`];
  const nextButtonDisabled = useMemo(() => {
    return currentPrompt.required ? !currentFormValue : false;
  }, [currentPrompt, currentFormValue]); 

  const { formName, question, name, additionalText, showTip, placeholder } =
    currentPrompt;

  function handleChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
    setInputValue(event.target.value);
  }

  const onSubmit: SubmitHandler<FormValues> = async (formData, event) => {
    updateForm(formData);

    if (currentQuestion === 8) {
      updateStep(1);
    } else if (currentQuestion <= 7) {
      updateQuestion(currentQuestion + 1);
    }
  };

  const onError: SubmitErrorHandler<FormValues> = async (errors, event) => {
    console.log({ errors });
  };

  function handleClick() {
    handleSubmit(onSubmit, onError)();
  }

  useEffect(() => {
    async function handleEnter(event: KeyboardEvent) {
      if (nextButtonDisabled) {
        return;
      }

      if (
        event.key === "Enter" &&
        event.metaKey === true &&
        event.shiftKey === true
      ) {
        if (currentQuestion > 0) {
          updateQuestion(currentQuestion - 1);
        }

        return;
      } else if (event.key === "Enter" && event.metaKey === true) {
        if (currentQuestion <= 7) {
          updateQuestion(currentQuestion + 1);
        } else if (currentQuestion === 8) {
          updateStep(1);
          updateQuestion(0);
        }
      }
    }

    document.addEventListener("keydown", handleEnter);

    return () => {
      document.removeEventListener("keydown", handleEnter);
    };
  }, [currentQuestion, nextButtonDisabled, updateQuestion, updateStep]);

  return (
    <ChatInterfaceContainer
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      key={currentQuestion}
      type="column"
      gap={3}
      stretchColumns
      ref={containerRef}
    >
      <Stack type="row" gap={2}>
        <Stack type="column" gap={1}>
          <Text
            style={{
              fontSize: "1.5rem",
              textTransform: "uppercase",
              letterSpacing: "1px",
              color: "#635ec0",
              fontFamily: "Catamaran",
              marginBottom: "-1rem"
            }}
          >
            {name}
          </Text>
          <QuestionText>{question}</QuestionText>
          {additionalText !== undefined && (
            <Text style={{ fontSize: "1.5rem", color: "#424D68" }}>
              {additionalText}
            </Text>
          )}
        </Stack>
      </Stack>

      {isText(currentPrompt) && <TextInput question={currentPrompt} />}

      {isCheckbox(currentPrompt) && (
        <CheckboxListContainer type="column" gap={2}>
          {currentPrompt.inputs.map((input) => (
            <Checkbox
              key={input.id}
              name={input.name}
              text={input.text}
              keyboardKey={input.keyboardKey}
            />
          ))}
        </CheckboxListContainer>
      )}

      {isMultiChoice(currentPrompt) && (
        <RadioContainer type="column" gap={2}>
          <Radio prompt={currentPrompt} />
        </RadioContainer>
      )}

      {showTip !== undefined && (
        <Text style={{ marginTop: "-2rem" }} className="info">
          Tip: Use <span>Shift</span> + <span>Enter</span> to make a line break
        </Text>
      )}

      <Stack type="row" gap={0} style={{ justifyContent: "space-between" }}>
        <ButtonGroup type="row" gap={1}>
          <Button disabled={nextButtonDisabled} onClick={handleClick}>Next</Button>

          {!nextButtonDisabled && (
            <Text className="info">
              or press <span>Enter </span>
            </Text>
          )}
        </ButtonGroup>

        <ButtonGroup type="row" gap={0} style={{ justifyContent: "flex-end" }}>
          <ButtonContainer
            style={{
              borderTopLeftRadius: "4px",
              borderBottomLeftRadius: "4px"
            }}
            onClick={() => {
              if (currentQuestion > 0) {
                updateQuestion(currentQuestion - 1);
              }
            }}
          >
            <UpButton
              style={{ fill: currentQuestion === 0 ? "darkgray" : "white" }}
            />
          </ButtonContainer>

          <ButtonContainer
            style={{
              borderTopRightRadius: "4px",
              borderBottomRightRadius: "4px"
            }}
            onClick={() => {
              if (currentQuestion === 8) {
                updateStep(1);
                updateQuestion(0);
              } else if (currentQuestion <= 7) {
                updateQuestion(currentQuestion + 1);
              }
            }}
          >
            <DownButton />
          </ButtonContainer>
        </ButtonGroup>
      </Stack>
    </ChatInterfaceContainer>
  );
}

export default ChatInterface;

const ChatInterfaceContainer = styled(Stack)`
  margin: 0 auto;
  width: clamp(600px, 65vw, 690px);
  overflow: visible;
  align-self: center;
  /* background: rgba(255, 255, 255, 0.2);
  mix-blend-mode: multiply;
  border-radius: 1rem;
  padding: 4rem;
  box-shadow: 0 1px 2px rgba(99, 94, 192, 0.15); */

  .info {
    opacity: 0;
    transition: opacity 0.3s ease-out;
  }

  &:focus,
  &:focus-within {
    .info {
      opacity: 1;
    }
  }

  @media (max-width: 950px) {
    width: 100%;

    .info {
      display: none;
    }
  }
`;

const KeyboardText = styled.kbd`
  color: white;
  background: #635ec0;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  text-transform: uppercase;
  font-size: 0.9rem;
  margin: 0 1ch;
`;

const Text = styled.p`
  font-size: 1.3rem;
  letter-spacing: 0.25px;
  color: #132142;

  span {
    font-weight: 600;
  }
`;

const QuestionText = styled.span`
  font-size: clamp(2.8rem, 5vw, 3.1rem);
  font-weight: 200;
  color: #132142;
  line-height: 1;
`;

const Button = styled.button`
  background: #635ec0;
  color: white;
  font-size: 1.4rem;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  border-radius: 4px;
  padding: 0.5rem 2rem;
  height: 32px;

  transition: background 300ms ease, color 300ms ease;

  :hover,
  :active,
  :focus {
    color: white;
    background: #4b45b1;
  }

  /* :focus {
    outline: 3px solid #635ec0;
    outline-offset: 4px;
  } */

  :disabled {
    filter: grayscale(0.7);
  }

  @media (max-width: 950px) {
    font-size: 1.8rem;
    height: 42px;
  }
`;

const ButtonGroup = styled(Stack)`
  /* justify-content: center; */

  /* :focus-within {
    outline: 3px solid #635ec0;
    outline-offset: 4px;
  } */
`;

const ButtonContainer = styled.button`
  background: #635ec0;
  display: none;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 32px;

  cursor: pointer;
  transition: background 300ms ease;

  :hover,
  :active {
    background: #4b45b1;
  }

  /* :focus {
    outline: 3px solid #635ec0;
    outline-offset: 4px;
  } */

  :disabled {
    filter: grayscale(1);
  }

  @media (max-width: 950px) {
    display: flex;
    width: 60px;
    height: 42px;
  }
`;

const UpButton = styled(ChevronUp)`
  fill: white;
  display: block;
  width: 100%;
  height: 100%;
`;

const DownButton = styled(ChevronDown)`
  fill: white;
  display: block;
  width: 100%;
  height: 100%;
`;

const CheckboxListContainer = styled(Stack)`
  grid-template-columns: 1fr 1fr;
`;

const RadioContainer = styled(Stack)`
  grid-template-columns: 1fr 1fr;
`;


/*


{
  "files": [
    {
      "path": "Field Research.csv",
      "preview": "blob:http://localhost:8000/b2b4c3d6-6112-4060-8db7-9820307c01d8"
    }
  ],
  "name": "Michael",
  "email": "michael@sympler.co",
  "company": "Sympelr",
  "briefName": "Vaccination Study",
  "form-1": "A response",
  "form-2": [
    "A response",
    "Another response",
    ""
  ],
  "form-3": "A response to the third question.",
  "form-4": "A response",
  "form-5": "Another response",
  "form-6": [
    "A response to the sixth question.",
    "Another response to the sixth question.",
    "And another response to the sixth question."
  ],
  "form-7": "A response.",
  "form-8": "A response to the eighth question.",
  "form-9": [
    "Raw Data",
    "Videos, Images, Gifs"
  ],
}

*/

interface BriefForm {
  files: Array<File>;
  name: string;
  email: string;
  company: string;
  briefName: string;
  ["form-1"]: string;
  ["form-2"]: Array<string>;
  ["form-3"]: string;
  ["form-4"]: string;
  ["form-5"]: string;
  ["form-6"]: Array<string>;
  ["form-7"]: string;
  ["form-8"]: string;
  ["form-9"]: Array<string>;
}

import React from "react";
import styled from "styled-components";
import Stack from "@components/Stack";
import useFormState from "@hooks/useFormState";
import FileIcons from "./FileIcons";
import { formatBytes } from "@utils";
import { File } from "@styled-icons/fa-solid/File";

function FileRows() {
  const form = useFormState((s) => s.form);
  const updateForm = useFormState((s) => s.updateForm);

  return (
    <SummaryContainer type="column" gap={1} stretchColumns>
      <StepAnswered>
        {form.files.length} file{form.files.length === 1 ? "" : "s"} attached
      </StepAnswered>

      <FileRowContainer type="column" gap={0} stretchColumns>
        {form.files.map((file) => {
          return (
            <FileRow key={file.name}>
              {FileIcons[file.type]}

              <FileName>{file.name}</FileName>

              <FileSize>{formatBytes(file.size)}</FileSize>

              <FileRemove
                onClick={(event) => {
                  event.stopPropagation();

                  updateForm({
                    files: form.files.filter(
                      (stateFile) => stateFile.name !== file.name
                    )
                  });
                }}
              >
                Remove
              </FileRemove>
            </FileRow>
          );
        })}

        {form.files.length === 0 && (
          <FileRow>
            <FileIcon />
            No materials attached.
          </FileRow>
        )}
      </FileRowContainer>
    </SummaryContainer>
  );
}

export default FileRows;

const FileRowContainer = styled(Stack)`
  border-radius: 8px;
  overflow: hidden;

  > div:nth-child(odd) {
    background: rgba(255, 255, 255, 0.15);
  }

  > div:nth-child(even) {
    background: rgba(255, 255, 255, 0.3);
  }
`;

const FileRow = styled.div`
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;

  mix-blend-mode: multiply;
`;

const FileIcon = styled(File)`
  display: inline-block;
  height: 22px;
  margin-right: 2ch;
  color: #635ec0;
`;

const FileNameContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

const FileName = styled.span`
  font-size: 1.55rem;
  color: #132142;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const FileSize = styled.span`
  margin: 0 2ch;
  font-size: 1.25rem;
  text-transform: uppercase;
  font-weight: bold;
  color: #3f3a95;
  align-self: center;
  white-space: nowrap;
  flex-grow: 1;
  text-align: left;
`;

const FileRemove = styled.span`
  color: #2d2a6b;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1.1rem;
  text-decoration: none;
  cursor: pointer;
  flex-shrink: 0;

  :hover {
    text-decoration: underline;
  }
`;

const SummaryContainer = styled(Stack)`
  /* background: #deddf2; */
  border-radius: 8px;
`;

const StepAnswered = styled.div`
  text-transform: uppercase;
  font-size: 14.5px;
  font-weight: bold;
  letter-spacing: 0.75px;
  color: #635ec0;
  text-align: center;

  span {
    font-weight: 400;
  }
`;

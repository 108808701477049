import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import Textarea from "react-textarea-autosize";
import {
  useFormContext,
  SubmitErrorHandler,
  SubmitHandler
} from "react-hook-form";
import { useMobileDetect } from "@hooks";
import useFormState, { FormState } from "@hooks/useFormState";
import Stack from "@components/Stack";
import { mergeRefs } from "@utils";
import { Error } from "@styled-icons/material/Error";
import { TextPrompt, FormValues } from "@utils/steps";

type BriefInputProps = {
  question: TextPrompt;
};

function TextInput({ question }: BriefInputProps) {
  const form = useFormState((s) => s.form);
  const updateForm = useFormState((s) => s.updateForm);
  const currentQuestion = useFormState((s) => s.currentQuestion);
  const updateQuestion = useFormState((s) => s.updateQuestion);
  const { isMobile } = useMobileDetect();
  const { register, handleSubmit } = useFormContext();

  const { formName, number, placeholder, textInputs } = question;
  const defaultValue = form[formName];

  const isMultipleInputs = textInputs !== 1;
  const singleInputRef = useRef<HTMLTextAreaElement | null>(null);
  const refMap = Array.from({ length: textInputs }).map((_) =>
    useRef<HTMLTextAreaElement | null>(null)
  );

  function getFirstEmptyRef() {
    for (const ref of refMap) {
      if (ref !== null && ref !== undefined) {
        const textContent = ref?.current?.value;

        if (textContent === "") {
          return ref;
        }
      }
    }

    return refMap[0];
  }

  function handleFocus() {
    if (textInputs === 1) {
      if (singleInputRef?.current && isMobile() === false) {
        singleInputRef.current.focus({ preventScroll: true });
        singleInputRef.current?.setSelectionRange(
          singleInputRef.current.value.length,
          singleInputRef.current.value.length
        );
      }
    } else {
      const refToFocus = getFirstEmptyRef();

      if (refToFocus?.current) {
        refToFocus.current.focus({ preventScroll: true });
        refToFocus.current?.setSelectionRange(
          refToFocus.current.value.length,
          refToFocus.current.value.length
        );
      }
    }
  }

  const onSubmit: SubmitHandler<FormValues> = async (formData, event) => {
    updateForm(formData);

    if (currentQuestion !== null && currentQuestion <= 8) {
      updateQuestion(currentQuestion + 1);
    }
  };

  const onError: SubmitErrorHandler<FormValues> = async (errors, event) => {
    console.log({ errors });
  };

  useEffect(() => {
    function handleEnter(event: KeyboardEvent) {
      if (
        event.key === "Enter" &&
        event.shiftKey === false &&
        event.metaKey === false
      ) {
        event.preventDefault();
        event.stopPropagation();
        handleSubmit(onSubmit, onError)();
      }
    }

    singleInputRef?.current?.addEventListener("keydown", handleEnter);

    return () => {
      singleInputRef?.current?.removeEventListener("keydown", handleEnter);
    };
  }, [singleInputRef, handleSubmit, onSubmit, onError]);

  if (isMultipleInputs) {
    return (
      <MultipleInputs
        question={question}
        refMap={refMap}
        handleFocus={handleFocus}
      />
    );
  }

  return (
    <Container
      key={currentQuestion}
      type="column"
      gap={1}
      stretchColumns
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      onAnimationComplete={handleFocus}
    >
      <InterfaceInput
        key={formName}
        autoComplete="off"
        name={formName}
        defaultValue={defaultValue}
        placeholder={placeholder ?? "Type your response here..."}
        maxLength={65535}
        ref={mergeRefs(singleInputRef, register)}
      />
    </Container>
  );
}

function MultipleInputs({
  question,
  refMap,
  handleFocus
}: {
  question: TextPrompt;
  refMap: Array<React.MutableRefObject<HTMLTextAreaElement | null>>;
  handleFocus: () => void;
}) {
  const currentQuestion = useFormState((s) => s.currentQuestion);
  const updateQuestion = useFormState((s) => s.updateQuestion);

  function handleEnters(index: number) {
    const isLastInput = index + 1 === question.textInputs;

    if (isLastInput) {
      const ref = refMap[index]?.current;
      if (ref !== null && ref !== undefined) {
        ref.blur();
        updateQuestion(currentQuestion + 1);
      }
    } else {
      const nextInputRef = refMap[index + 1]?.current;

      if (nextInputRef !== null && nextInputRef !== undefined) {
        nextInputRef.focus({ preventScroll: true });
        nextInputRef.setSelectionRange(
          nextInputRef.value.length,
          nextInputRef.value.length
        );
      }
    }
  }

  const { formName } = question;

  return (
    <Container
      key={formName}
      type="column"
      gap={2}
      stretchColumns
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      onAnimationComplete={handleFocus}
    >
      {Array.from({ length: question.textInputs }).map((_, index) => {
        return (
          <SingleInput
            key={index}
            inputNumber={index}
            question={question}
            ref={refMap[index]}
            handleEnter={() => handleEnters(index)}
          />
        );
      })}
    </Container>
  );
}

const SingleInput = React.forwardRef<
  HTMLTextAreaElement,
  {
    inputNumber: number;
    question: TextPrompt;
    handleEnter: () => void;
  }
>(({ inputNumber, question, handleEnter }, ref) => {
  const form = useFormState((s) => s.form);
  const updateForm = useFormState((s) => s.updateForm);
  const { formName, textInputs } = question;

  const [localValue, setLocalValue] = useState(
    form?.[formName]?.[inputNumber] ?? ""
  );

  const currentFormData = form[formName];

  function initFieldData() {
    return Array.from({ length: textInputs }).map((_) => "");
  }

  function handleBlur() {
    if (typeof currentFormData === "string") return;

    const currentData =
      currentFormData.length > 0 ? currentFormData : initFieldData();

    currentData[inputNumber] = localValue;

    const formData = { [formName]: currentData };

    updateForm(formData);
  }

  useEffect(() => {
    if (ref === null || typeof ref === "function") return;

    function handleEnterPress(event: KeyboardEvent) {
      if (event.key === "Enter" && event.shiftKey === false) {
        event.preventDefault();
        event.stopPropagation();
        handleEnter();
      }
    }

    ref?.current?.addEventListener("keydown", handleEnterPress);

    return () => {
      ref?.current?.removeEventListener("keydown", handleEnterPress);
    };
  }, [ref, handleEnter]);

  return (
    <InterfaceInputContainer stretchColumns>
      <InterfaceInput
        ref={mergeRefs(ref)}
        autoComplete="off"
        tabIndex={0}
        maxLength={65535}
        value={localValue}
        style={{ paddingLeft: "4rem" }}
        onChange={(e) => setLocalValue(e.target.value)}
        onBlur={handleBlur}
      />
      <InputNumber>{inputNumber + 1}</InputNumber>
    </InterfaceInputContainer>
  );
});

export default TextInput;

const Container = styled(Stack)`
  width: 100%;
`;

const InterfaceInput = styled(Textarea)`
  background: none;
  resize: none;
  appearance: none;
  outline: none;
  border: none;
  overflow: hidden;
  font-size: clamp(1.6rem, 5vw, 1.95rem);
  color: #635ec0;
  padding: 0.75rem 0rem;
  box-shadow: rgb(99, 94, 192, 0.3) 0px 1px;
  transition: box-shadow 0.1s ease-out;

  ::placeholder {
    color: rgba(99, 94, 192, 0.75);
  }

  :focus,
  :active {
    box-shadow: rgba(99, 94, 192, 1) 0px 2px;
  }
`;

const InstructionText = styled(motion.p)`
  color: tomato;
  letter-spacing: 0.5px;
  font-size: 1.3rem;
  display: flex;
  align-items: center;
`;

const ErrorIcon = styled(Error)`
  color: tomato;
  width: 18px;
  height: 18px;
  margin-right: 1ch;
  flex-shrink: 0;
  align-self: flex-start;
`;

const ButtonGroup = styled(Stack)`
  position: absolute;
  bottom: 0;
  right: 2rem;
  transform: translateY(70%);
`;

const SendButton = styled(motion.div)`
  display: inline-flex;
  align-items: center;
  background: #635ec0;
  border-radius: 9999px;
  padding: 0.75rem 1.75rem;
  cursor: pointer;
  transition: background 300ms ease;

  :hover,
  :active {
    background: #4b45b1;
  }
`;

const SkipButton = styled(SendButton)`
  background: #afb3bf;

  :hover,
  :active {
    background: #999eae;
  }
`;

const SendButtonText = styled(motion.p)`
  color: white;
  font-size: 1.25rem;
  font-weight: bold;
  letter-spacing: 0.5px;
  /* font-weight: bold; */
  text-transform: uppercase;

  span {
    font-size: 1rem;
    font-weight: 400;
    text-transform: none;
    letter-spacing: 0px;
  }
`;

const Text = styled.div`
  font-size: 1.25rem;
  letter-spacing: 0.1px;
  color: #132142;
  transform: translateY(0.65rem);

  span {
    font-weight: bold;
  }
`;

const InputNumber = styled.span`
  user-select: none;
  font-size: 1.6rem;
  position: absolute;
  top: 1.1rem;
  left: 1rem;
  color: #635ec0;

  /* :after {
    content: "";
    position: absolute;
    right: -1rem;
    height: 100%;
    width: 1px;
    background: #635ec0;
    border-radius: 9999px;
  } */
`;

const InterfaceInputContainer = styled(Stack)`
  :focus-within {
    span {
      font-weight: bold;
    }
  }
`;

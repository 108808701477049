import React, { useEffect } from "react";
import { navigate } from "gatsby";
import styled from "styled-components";
import { motion, useAnimation } from "framer-motion";
import Stack from "@components/Stack";
import ChatBot from "@components/ChatBot";
import Avatar from "@components/Avatar";
import { useWindupString } from "windups";

function ExitScreen() {
  const [text] = useWindupString(
    "Thank you for submitting your brief! We'll be in touch shortly."
  );

  useEffect(() => {
    function handleEnter(event: KeyboardEvent) {
      if (event.key === "Enter") {
        return;
      }
    }

    document.body.addEventListener("keydown", handleEnter);

    return () => {
      document.body.removeEventListener("keydown", handleEnter);
    };
  }, []);

  return (
    <ExitScreenContainer type="column" gap={2}>
      <InterfaceText>{text}</InterfaceText>
      <Button
        onClick={() => {
          navigate("/");
        }}
      >
        Back to home
      </Button>
    </ExitScreenContainer>
  );
}

export default ExitScreen;

const ExitScreenContainer = styled(Stack)`
  width: 100%;
  height: 100%;
  place-content: center;
  position: relative;
  z-index: 10;
`;

const AvatarContainer = styled(motion.div)`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InterfaceText = styled(motion.div)`
  font-size: clamp(3.1rem, 5vw, 3.3rem);
  font-weight: 200;
  color: #132142;
  line-height: 1;
`;

const InstructionText = styled(motion.p)`
  width: 100%;
  color: #1f2021;
  letter-spacing: 0.5px;
  font-size: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const KeyboardText = styled.span`
  color: white;
  background: #635ec0;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  text-transform: uppercase;
  font-size: 0.9rem;
  margin: 0 1ch;
`;

const Button = styled.div`
  background: #635ec0;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.6rem;
  letter-spacing: 1px;
  border-radius: 4px;
  padding: 0.5rem 3rem;
  transition: background 300ms ease;
  max-width: 300px;
  margin: 0 auto;
  cursor: pointer;

  :hover,
  :active {
    background: #4b45b1;
  }
`;

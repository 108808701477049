import { useEffect } from "react";
import create from "zustand";
import { persist } from "zustand/middleware";
import { FormValues } from "@utils/steps";

export type FormState = FormValues & { files: Array<FileType> };

type StatusType = "welcome" | "form" | "exit";

export type FileType = File;

type FormFieldMeta = {
  timeSent?: string;
};

type MetaData = Record<number, Record<number, FormFieldMeta>>;

export interface UserDetails {
  name: string;
  email: string;
  company: string;
  briefName: string;
}

type Message = {
  stepNumber: number;
  questionNumber: number;
  timeCreated: string;
  message: string;
};

const initialUserDetails: UserDetails = {
  name: "",
  email: "",
  company: "",
  briefName: ""
};

const useFormState = create<{
  form: FormState;
  status: StatusType;
  updateForm: (newForm: Partial<FormState>) => void;
  updateStatus: (newStatus: StatusType) => void;
  userDetails: UserDetails;
  updateUserDetails: (newDetails: Partial<UserDetails>) => void;
  currentStep: number;
  updateStep: (newStep: number) => void;
  currentQuestion: number;
  updateQuestion: (newQuestion: number) => void;
  resetForm: () => void;
}>(
  persist(
    (set) => ({
      form: {
        files: [] as Array<FileType>,
        ["form-1"]: "",
        ["form-2"]: [],
        ["form-3"]: "",
        ["form-4"]: "",
        ["form-5"]: "",
        ["form-6"]: [],
        ["form-7"]: "",
        ["form-8"]: "",
        ["form-9"]: []
      } as FormState,
      status: "welcome",
      updateForm: (newForm: Partial<FormState>) =>
        set((state) => {
          return {
            form: {
              ...state.form,
              ...newForm
            }
          };
        }),
      updateStatus: (newStatus: StatusType) => {
        set({ status: newStatus });
      },
      userDetails: initialUserDetails,
      updateUserDetails: (newDetails: Partial<UserDetails>) =>
        set((state) => ({
          userDetails: { ...state.userDetails, ...newDetails }
        })),
      currentStep: 0,
      updateStep: (newStep: number) => set({ currentStep: newStep }),
      currentQuestion: 0,
      updateQuestion: (newQuestion: number) =>
        set({ currentQuestion: newQuestion }),
      resetForm: () =>
        set({
          form: {
            files: [] as Array<FileType>,
            ["form-1"]: "",
            ["form-2"]: [],
            ["form-3"]: "",
            ["form-4"]: "",
            ["form-5"]: "",
            ["form-6"]: [],
            ["form-7"]: "",
            ["form-8"]: "",
            ["form-9"]: []
          } as FormState,
          currentStep: 0,
          currentQuestion: 0,
          status: "welcome",
          userDetails: initialUserDetails
        })
    }),
    { name: "brief-state", version: 3, blacklist: ["status"] }
  )
);

/**
 * Used to reset persisted files on page load to empty array — as Files
 * cannot persist in localStorage across page refreshes without errors.
 */

export function useFormFileReset() {
  const updateForm = useFormState((s) => s.updateForm);

  useEffect(() => {
    function handleUnload() {
      updateForm({ files: [] });
    }

    handleUnload();
  }, [updateForm]);
}

export default useFormState;

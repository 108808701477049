import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { useForm, FormProvider } from "react-hook-form";
import { Toaster } from "react-hot-toast";
import { PageProps } from "gatsby";
import useFormState, { useFormFileReset } from "@hooks/useFormState";
import WelcomeScreen from "@components/WelcomeScreen";
import SEO from "@components/SEO";
import FormScreen from "@components/FormScreen";
import ExitScreen from "@components/ExitScreen";
import { AvatarProvider } from "@components/Avatar";
import DecorativeBackground from "@components/DecorativeBackground";
import { FormValues } from "@utils/steps";

function BriefPage(props: PageProps) {
  const status = useFormState((s) => s.status);
  const formMethods = useForm<FormValues>({
    reValidateMode: "onSubmit",
    shouldUnregister: false
  });

  useFormFileReset();

  return (
    <FormProvider {...formMethods}>
      <SEO title="Sympler Brief" pathname={props.location.pathname} />

      <AvatarProvider>
        <BriefWrapper>
          {status === "welcome" && <WelcomeScreen />}

          {status === "form" && <FormScreen />}

          {status === "exit" && <ExitScreen />}

          <DecorativeBackground />

          <Toaster
            position="bottom-center"
            reverseOrder={false}
            toastOptions={{
              style: {
                fontSize: "14.5px",
                letterSpacing: "0.25px",
                color: "#142132",
                margin: "50px"
              },
              success: {
                iconTheme: {
                  primary: "#635ec0",
                  secondary: "#ffffff"
                }
              }
            }}
          />
        </BriefWrapper>
      </AvatarProvider>
    </FormProvider>
  );
}

export default BriefPage;

const BriefWrapper = styled.main`
  width: 100%;
  height: 100%;
  max-width: 100vw;
  min-height: 100vh;
  display: grid;
  /* place-content: center; */
  grid-template-columns: minmax(0, 1fr);
  position: relative;
  background: linear-gradient(
    138.24deg,
    rgba(219, 231, 240, 0.26) 4.35%,
    rgba(159, 167, 238, 0.36) 94.6%
  );
  padding: 0 10%;
  /* overflow: hidden; */

  @media (max-width: 950px) {
    padding: 0 5%;
  }
`;

const Container = styled(motion.div)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
  height: 100%;
`;

const OrbContainer = styled(motion.div)`
  width: 100%;
  height: 100%;

  svg {
    height: 100%;
    overflow: visible;
    transform-origin: center center !important;
  }
`;

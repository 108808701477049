import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

import * as Toggle from "@radix-ui/react-toggle";
import useFormState from "@hooks/useFormState";
import { Check } from "@styled-icons/boxicons-regular/Check";

interface CheckboxProps {
  name: string;
  text: string;
  details?: string;
  keyboardKey: string;
}

function Checkbox({ name, text, keyboardKey, details = "" }: CheckboxProps) {
  const form = useFormState((s) => s.form);
  const updateForm = useFormState((s) => s.updateForm);

  const field = "form-9";
  const items = form[field] ?? [];

  const isChecked = items.includes(text);

  function handleChange() {
    if (isChecked) {
      updateForm({ [field]: items.filter((item) => item !== text) });
    } else {
      updateForm({ [field]: [...items, text] });
    }
  }

  useEffect(() => {
    async function handleKeyPress(event: KeyboardEvent) {
      if (event.key === keyboardKey) {
        if (isChecked) {
          updateForm({ [field]: items.filter((item) => item !== text) });
        } else {
          updateForm({ [field]: [...items, text] });
        }
      }
    }

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [isChecked, updateForm, items]);

  return (
    <ToggleContainer pressed={isChecked} onPressedChange={handleChange}>
      {text}

      {/* <KeyContainer>{keyboardKey}</KeyContainer> */}
    </ToggleContainer>
  );
}

export default Checkbox;

const ToggleContainer = styled(Toggle.Root)`
  background: rgba(255, 255, 255, 0.2);
  mix-blend-mode: multiply;
  padding: 1rem 0;
  min-width: 175px;
  font-size: 1.5rem;
  letter-spacing: 0.25px;
  color: #635ec0;
  border-radius: 4px;
  /* border: 1px solid #635ec0; */
  box-shadow: 0 1px 2px rgba(99, 94, 192, 0.15);
  position: relative;
  transition: background 0.1s ease-out, color 0.1s ease-out;

  :hover {
    background: rgba(255, 255, 255, 0.3);
  }

  &[data-state="on"] {
    background: #635ec0;
    color: white;

    :hover {
      background: #4540a4;
    }
  }
`;

const KeyContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #635ec0;
  text-transform: uppercase;
  border-radius: 4px;
  width: 20px;
  height: 20px;
  font-size: 1.4rem;
`;

import React, { useState, useCallback, useRef, useMemo, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { useFormContext } from "react-hook-form";
import useFormState from "@hooks/useFormState";
import ChatInterface from "@components/ChatInterface";
import Stack from "@components/Stack";
import { Avatar } from "@components/ChatMessage/UserMessage";
import UploadInterface from "@components/UploadInterface";
import SummaryInterface from "@components/SummaryInterface";
import BriefSteps from "@components/BriefSteps";
import MobileBriefSteps from "@components/MobileBriefSteps";
import { useLockBodyScroll } from "@hooks";
import toast from "react-hot-toast";
import steps, { ChatStep, FormValues } from "@utils/steps";
import * as AlertDialog from "@radix-ui/react-alert-dialog";
import * as Toggle from "@radix-ui/react-toggle";
import * as Tooltip from "@radix-ui/react-tooltip";
import * as HoverCard from "@radix-ui/react-hover-card";
import * as Separator from "@radix-ui/react-separator";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { Close } from "@styled-icons/evaicons-solid/Close";
import { UploadFile } from "@styled-icons/material/UploadFile";
import { ListCheck } from "@styled-icons/bootstrap/ListCheck";
import { KeyboardArrowDown } from "@styled-icons/material/KeyboardArrowDown";
import { Checkmark } from "@styled-icons/evaicons-solid/Checkmark";
import { Edit } from "@styled-icons/fluentui-system-filled/Edit";

const notification = (number: number | undefined) => toast(number ? `Please complete question ${number} to continue` : `Please complete questions to continue`);

function FormScreen() {
  const [showingOverlay, setShowingOverlay] = useState(false);
  const briefInputRef = useRef<HTMLInputElement | null>(null);

  const form = useFormState((s) => s.form);
  const currentStep = useFormState((s) => s.currentStep);
  const currentQuestion = useFormState((s) => s.currentQuestion);
  const updateStep = useFormState((s) => s.updateStep);
  const updateQuestion = useFormState((s) => s.updateQuestion);
  const updateUserDetails = useFormState((s) => s.updateUserDetails);
  const resetForm = useFormState((s) => s.resetForm);
  const userDetails = useFormState((s) => s.userDetails);
  const status = useFormState((s) => s.status);
  const updateStatus = useFormState((s) => s.updateStatus);
  const [missingRequiredInfo, setMissingRequiredInfo] = useState<number>();
  const [localBriefName, setLocalBriefName] = useState(
    userDetails?.briefName ?? ""
  );
  const { reset } = useFormContext();

  const { type } = steps[currentStep];

  const hasFiles = form?.files.length > 0;
  const numberOfFiles = form?.files.length ?? 0;

  const toggleOverlay = useCallback(() => {
    setShowingOverlay((current) => !current);
  }, [setShowingOverlay]);

  const finalStepsEnabled = useMemo(() => {
    const step = steps[0] as ChatStep;
    const requiredPrompts = step.prompts.filter(p => p.required);
    for (const prompt of requiredPrompts) {
      const value = (form as any)[prompt.formName];
      if (!value || value.length === 0) {
        return false;
      }
    }
    
    return true;
  }, [steps, form]);

  useEffect(() => {
    const step = steps[0] as ChatStep
    if (missingRequiredInfo !== undefined) {
      return
    }
    if (currentQuestion > 0) {
      const prompts = step.prompts;
      for (const prompt of prompts) {
        if (prompt.required){
          const responses = form?.[prompt.formName] ?? [];
          if (Array.isArray(responses)) {
            if (responses.length === 0) {
              setMissingRequiredInfo(prompt.id)
            }
          } else {
            if (!responses) {
              setMissingRequiredInfo(prompt.id)
            }
          }
        }
      }
    }
  }, [currentQuestion, form]);

  function handleClick() {
    resetForm();
    reset();
  }

  function handleBlur() {
    if (localBriefName !== "" && localBriefName !== userDetails.briefName) {
      updateUserDetails({ briefName: localBriefName });

      toast.success(`Successfully updated brief name`);
    }
  }

  function handleFocus() {
    if (briefInputRef !== null && briefInputRef.current !== null) {
      briefInputRef.current.focus();
    }
  }

  useLockBodyScroll(showingOverlay);

  return (
    <>
      {showingOverlay && (
        <Overlay onClick={() => setShowingOverlay(false)}>
          <BriefSteps />
        </Overlay>
      )}

      <FormWrapper type="row" gap={4} stretchColumns>
        {/* <MobileBriefSteps toggleOverlay={toggleOverlay} /> */}

        <InterfaceContainer type="column" gap={4} stretchColumns>
          {currentStep === 0 && (
            <DetailsContainer type="column" gap={1}>
              {(steps[0] as ChatStep).prompts.map((prompt) => {
                return (
                  <QuestionNumber
                    key={prompt.formName}
                    number={prompt.number - 1}
                    formName={prompt.formName}
                  />
                );
              })}
            </DetailsContainer>
          )}

          <FormScreenHeader type="row" gap={0} stretchColumns>
            <Stack
              type="row"
              gap={1}
              style={{
                alignSelf: "center"
              }}
            >
              <Stack type="row" gap={1}>
                <HoverCard.Root>
                  <HoverCard.Trigger>
                    <Avatar name={userDetails.name} size={2.7} />
                  </HoverCard.Trigger>
                  <StyledContent side="bottom" sideOffset={5} align="center">
                    <Stack type="column" gap={1}>
                      <Stack type="row" gap={4}>
                        <Stack type="column" gap={0}>
                          <Text>{userDetails.name}</Text>
                          <Text
                            style={{ fontSize: "1.3rem", color: "#5B6470" }}
                          >
                            {userDetails.email}
                          </Text>
                        </Stack>
                        <Avatar name={userDetails.name} size={4} />
                      </Stack>

                      <Text
                        onClick={handleClick}
                        style={{
                          color: "#635ec0",
                          fontSize: "1.4rem",
                          cursor: "pointer"
                        }}
                      >
                        Looking to start over?
                      </Text>
                    </Stack>
                    <StyledArrow offset={18} />
                  </StyledContent>
                </HoverCard.Root>
                <Text
                  style={{
                    fontSize: "1.7rem",
                    letterSpacing: "0.5px",
                    color: "#5A647B",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }}
                >
                  {userDetails.company}
                </Text>
              </Stack>

              <StyledSeparator orientation="vertical" />

              <Text
                style={{
                  fontSize: "1.7rem",
                  letterSpacing: "0.5px",
                  color: "#5A647B",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis"
                }}
              >
                {userDetails?.briefName || "Untitled Brief"}
              </Text>

              <StyledSeparator orientation="vertical" />

              <DropdownMenu.Root>
                <DropdownMenu.Trigger>
                  <DropdownTrigger type="row" gap={0.5}>
                    <Stack type="row" gap={1}>
                      <Text
                        style={{
                          fontSize: "1.7rem",
                          letterSpacing: "0.5px",
                          color: "#5A647B"
                        }}
                      >
                        {currentStep === 0
                          ? "Brief Information"
                          : currentStep === 1
                          ? "Background Materials"
                          : "Summary"}
                      </Text>
                    </Stack>

                    <TriggerIconContainer>
                      <TriggerIcon />
                    </TriggerIconContainer>
                  </DropdownTrigger>
                </DropdownMenu.Trigger>
                <DropdownContent sideOffset={5} align="start">
                  <DropdownItem onSelect={() => updateStatus("welcome")}>
                    <DropdownStack
                      type="row"
                      gap={1}
                      isActive={status === "welcome"}
                      disabled={false}
                    >
                      {/* <DropdownStep>Step One</DropdownStep> */}
                      <DropdownName>Your Information</DropdownName>
                    </DropdownStack>
                  </DropdownItem>
                  <DropdownItem onSelect={() => updateStep(0)}>
                    <DropdownStack
                      type="row"
                      gap={1}
                      isActive={currentStep === 0}
                      disabled={false}
                    >
                      <DropdownName>Brief Information</DropdownName>
                    </DropdownStack>
                  </DropdownItem>
                  <DropdownItem onSelect={() => {
                    if (finalStepsEnabled) {
                      updateStep(1);
                    } else {
                      notification(missingRequiredInfo)
                    }
                  }}>
                    <DropdownStack
                      type="row"
                      gap={1}
                      isActive={currentStep === 1}
                      disabled={!finalStepsEnabled}
                    >
                      {/* <DropdownStep>Step Three</DropdownStep> */}
                      <DropdownName>Background Materials</DropdownName>

                      {form?.files.length > 0 && (
                        <StepFiles>
                          {form.files.length} File
                          {form.files.length === 1 ? "" : "s"}
                        </StepFiles>
                      )}
                    </DropdownStack>
                  </DropdownItem>
                  <DropdownItem onSelect={() => {
                    if (finalStepsEnabled) {
                      updateStep(2);
                    } else {
                      notification(missingRequiredInfo)
                    }
                  }}>
                    <DropdownStack
                      type="row"
                      gap={1}
                      isActive={currentStep === 2}
                      disabled={!finalStepsEnabled}
                    >
                      {/* <DropdownStep>Step Four</DropdownStep> */}
                      <DropdownName>Summary</DropdownName>
                    </DropdownStack>
                  </DropdownItem>
                </DropdownContent>
              </DropdownMenu.Root>
            </Stack>
          </FormScreenHeader>

          <AnimatePresence exitBeforeEnter>
            {currentStep === 0 ? (
              <ChatInterface key="chat" currentStep={currentStep} />
            ) : currentStep === 1 ? (
              <UploadInterface key="upload" currentStep={currentStep} />
            ) : currentStep === 2 ? (
              <SummaryInterface
                key="summary"
                currentStep={currentStep}
                handleFocus={handleFocus}
              />
            ) : null}
          </AnimatePresence>
        </InterfaceContainer>
      </FormWrapper>
    </>
  );
}

export default FormScreen;

const BriefAlertContainer = styled.div`
  position: absolute;
  top: 10rem;
  right: 0;
`;

export function BriefAlert() {
  const resetForm = useFormState((s) => s.resetForm);
  const { reset } = useFormContext();

  function handleClick() {
    resetForm();
    reset();
  }

  return (
    <AlertDialog.Root>
      <AlertDialog.Trigger>
        Click here to start the brief over.
      </AlertDialog.Trigger>
      <AlertOverlay />
      <AlertContent>
        <Stack type="column" gap={1}>
          <AlertTitle>Delete Brief</AlertTitle>
          <AlertDescription>
            Are you sure? This will erase all progress.
          </AlertDescription>

          <ButtonGroup type="column" gap={1} stretchColumns>
            <AlertAction onClick={handleClick}>Start over</AlertAction>
            <AlertCancel>Cancel</AlertCancel>
          </ButtonGroup>
        </Stack>
      </AlertContent>
    </AlertDialog.Root>
  );
}

const UserProfileContainer = styled(Stack)`
  border-radius: 1rem;
  padding: 1.5rem 2.5rem;
  background: rgba(255, 255, 255, 0.5);
  mix-blend-mode: multiply;
  box-shadow: 0 1px 2px rgba(99, 94, 192, 0.15);
  display: inline-grid;
`;

const UserDetails = styled(Stack)``;

const UserName = styled.div`
  font-size: 1.7rem;
  color: #132142;
`;

const UserCompany = styled.div`
  font-size: 1.6rem;
  color: #132142;
`;

const FormWrapper = styled(Stack)`
  width: 100%;
  height: 100%;
  max-width: 1250px;
  margin: 0 auto;
  justify-content: center;
  grid-template-columns: auto;
  z-index: 3;
  align-items: flex-start;

  @media (max-width: 950px) {
    grid-auto-flow: row;
    grid-template-columns: 1fr;
    grid-gap: 4rem;
    grid-template-rows: auto auto;
    align-content: center;
  }
`;

const InterfaceContainer = styled(Stack)`
  --top-gap: 8rem;
  --side-gap: 5rem;
  width: 100%;
  height: 100%;
  /* padding: var(--top-amount) 0 calc(var(--top-amount) / 2) 0; */
  /* position: sticky;
  top: 50%; */
  z-index: 3;
  /* border: 1px solid red; */
  /* transform: translateY(-50%); */
  justify-content: center;
  align-content: flex-start;
  padding: var(--top-gap) 0;
`;

const Overlay = styled(motion.div)`
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(6px);
  z-index: 1001;
  padding: 0 2.5%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Text = styled.p`
  color: #142132;
  font-size: 1.9rem;
`;

const TextInput = styled.input`
  appearance: none;
  border: none;
  background: none;
  color: #434d5b;
  font-size: 1.55rem;

  ::placeholder {
    color: #727a84;
  }
`;

const AlertOverlay = styled(AlertDialog.Overlay)`
  background-color: rgba(0, 0, 0, 0.35);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(2px);
`;

const AlertContent = styled(AlertDialog.Content)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 1rem;
  padding: 3rem;
  background: rgba(255, 255, 255, 1);
  mix-blend-mode: multiply;
  box-shadow: 0 1px 2px rgba(99, 94, 192, 0.15);
`;

const AlertTitle = styled(AlertDialog.Title)`
  color: #635ec0;
`;

const AlertDescription = styled(AlertDialog.Description)`
  color: #132142;
  font-size: 1.6rem;
`;

const ButtonGroup = styled(Stack)`
  margin-top: 1rem;
`;

const AlertAction = styled(AlertDialog.Action)`
  color: white;
  width: 100%;
  text-align: center;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 1.4rem;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  background: #635ec0;
  transition: background 300ms ease;

  :hover,
  :active {
    background: #4b45b1;
  }
`;

const AlertCancel = styled(AlertDialog.Cancel)`
  width: 100%;
  text-align: center;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 1.4rem;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  /* color: white;
  background: #afb3bf;
  transition: background 300ms ease;

  :hover,
  :active {
    background: #999eae;
  } */

  color: #635ec0;
  background: white;
  border: 1px solid #635ec0;
`;

const DeleteButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  background: #635ec0;
  color: white;
  mix-blend-mode: multiply;
  border-radius: 9999px;
  font-weight: bold;
  width: 3.5rem;
  height: 3.5rem;
  padding: 0.5rem;
  cursor: pointer;
  box-shadow: 0 1px 2px rgba(99, 94, 192, 0.15);
`;

const DeleteButton = styled(Close)`
  width: 100%;
  height: 100%;
  color: white;
`;

const UploadIcon = styled(UploadFile)`
  width: 100%;
  height: 100%;
  color: inherit;
  padding: 1rem;
`;

const SummaryIcon = styled(ListCheck)`
  width: 100%;
  height: 100%;
  color: inherit;
  padding: 1.1rem;
`;

const Content = styled(Tooltip.Content)`
  background: rgba(255, 255, 255, 0.3);
  mix-blend-mode: multiply;
  font-size: 1.5rem;
  padding: 0.75rem 1.55rem;
  color: #635ec0;
  letter-spacing: 0.25px;
  border-radius: 4px;
`;

const Arrow = styled(Tooltip.Arrow)`
  fill: rgba(255, 255, 255, 0.3);
`;

const FileCounter = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(25%, 25%);
  width: 18px;
  height: 18px;
  background: dodgerblue;
  color: white;
  font-size: 1.15rem;
  font-weight: bold;
  border-radius: 9999px;
`;

const ToggleContainer = styled(Toggle.Root)`
  background: rgba(255, 255, 255, 0.2);
  mix-blend-mode: multiply;
  padding: 1rem 0;
  min-width: 175px;
  font-size: 1.5rem;
  letter-spacing: 0.25px;
  color: #635ec0;
  border-radius: 4px;
  /* border: 1px solid #635ec0; */
  box-shadow: 0 1px 2px rgba(99, 94, 192, 0.15);
  position: relative;
  transition: background 0.1s ease-out, color 0.1s ease-out;

  &[data-state="on"] {
    background: #635ec0;
    color: white;
  }
`;

const scaleIn = keyframes`
  0% {
    opacity: 0;
    transform: scale(0) translateY(-1rem);
  }

  100% {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
`;

const StyledContent = styled(HoverCard.Content)`
  border-radius: 4px;
  padding: 2rem;
  font-size: 1.5rem;
  color: #142132;
  background: #ffffff;
  mix-blend-mode: multiply;
  transform-origin: var(--radix-hover-card-content-transform-origin);
  animation: ${scaleIn} 300ms ease;
`;

const StyledArrow = styled(HoverCard.Arrow)`
  fill: #ffffff;
  mix-blend-mode: multiply;
`;

const StyledSeparator = styled(Separator.Root)`
  width: 2px;
  height: 100%;
  background: #635ec0;
  border-radius: 9999px;
`;

const DropdownTrigger = styled(Stack)`
  /* background: rgba(255, 255, 255, 0.15);
  box-shadow: 0 1px 2px rgba(99, 94, 192, 0.15);
  mix-blend-mode: multiply; */

  /* transition: background 300ms ease, color 300ms ease; */
  /* border-radius: 4px; */

  /* :hover {
    background: rgba(255, 255, 255, 0.3);
  } */
`;

const DropdownContent = styled(DropdownMenu.Content)`
  background: #eaf2fe;
  border-radius: 4px;
  mix-blend-mode: multiply;
  display: grid;
  grid-auto-flow: row;
  overflow: hidden;
`;

const DropdownStack = styled(Stack)<{ isActive: boolean, disabled: boolean }>`
  cursor: default;
  padding: 0.75rem 1.5rem;
  min-width: 195px;
  cursor: pointer;
  outline: none;
  color: #635ec0;

  :hover {
    background: rgba(99, 94, 192, 1);
    color: white;
  }

  ${(props) => props.disabled && `
    opactiy: 0.5;
    color: gray;
    :hover {
      background: rgba(128,128,128,1);
    }
  `}

  ${(props) =>
    props.isActive &&
    `
     background: #4540A4;
     color: white;

     :hover {
       background: #4540A4;
     }
  `}
`;

const DropdownItem = styled(DropdownMenu.Item)``;

const DropdownStep = styled.span`
  font-family: "Catamaran", sans-serif;
  color: inherit;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 1.35rem;
  color: #635ec0;
`;

const DropdownName = styled.span`
  color: inherit;
  font-size: 1.45rem;
  letter-spacing: 0.5px;
  align-self: flex-end;
`;

const TriggerIconContainer = styled.div`
  width: 2rem;
  height: 2rem;
  /* padding: 1px; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 9999px;
  /* background: #8c88d1; */
  /* background: #635ec0; */
  transition: background 300ms ease;

  :hover {
    background: #4540a4;
  }
`;

const TriggerIcon = styled(KeyboardArrowDown)`
  width: 100%;
  height: 100%;
  color: #4540a4;
  display: block;
`;

const DetailsContainer = styled(Stack)`
  position: absolute;
  top: var(--top-gap);
  left: 0;
  transform: translateX(calc(-100% - var(--side-gap)));

  @media (max-width: 950px) {
    grid-auto-flow: column;
    width: 100%;
    overflow: auto;
    display: none;
  }
`;

function QuestionNumber({
  number,
  formName
}: {
  number: number;
  formName: keyof FormValues;
}) {
  const form = useFormState((s) => s.form);
  const currentQuestion = useFormState((s) => s.currentQuestion);
  const step = steps[0] as ChatStep;
  const currentPrompt = step?.prompts?.[number as number];
  const updateStep = useFormState((s) => s.updateStep);
  const updateQuestion = useFormState((s) => s.updateQuestion);
  const [missingRequiredInfo, setMissingRequiredInfo] = useState<number>();

  

  const responses = form?.[formName] ?? [];

  const isActive = number === currentQuestion;

  const hasResponse = Array.isArray(responses)
    ? responses.filter((response) => response.trim()).length > 0
    : responses.length > 0;

  const enabled = useMemo(() => {
    if (number > 0) {
      const pastPrompts = step.prompts.slice(0, number);
      for (const prompt of pastPrompts) {
        if (prompt.required){
          const responses = form?.[prompt.formName] ?? [];
          if (Array.isArray(responses)) {
            if (responses.length === 0) {
              return false;
            }
          } else {
            if (!responses) {
              return false;
            }
          }
        }
      }
    }

    return true;
  }, [currentQuestion, form]);

  useEffect(() => {
    const step = steps[0] as ChatStep
    if (missingRequiredInfo !== undefined) {
      return
    }
    
    if (currentQuestion > 0) {
      const prompts = step.prompts;
      for (const prompt of prompts) {
        if (prompt.required){
          const responses = form?.[prompt.formName] ?? [];
          if (Array.isArray(responses)) {
            if (responses.length === 0) {
              setMissingRequiredInfo(prompt.id)
            }
          } else {
            if (!responses) {
              setMissingRequiredInfo(prompt.id)
            }
          }
        }
      }
    }

  }, [currentQuestion, form]);

  function handleClick() {
    console.log(`click: `, number);
    updateStep(0);
    updateQuestion(number);
  }

  const questionProps = useMemo(() => {
    const props = {
      isActive: !enabled ? false : isActive,
      hasResponse: !enabled ? false: hasResponse
    }

    if (enabled) {
      // console.log(`enabled: ${currentQuestion}`);
      return {
        ...props,
        onClick: handleClick
      }
    } else {
      return {
        ...props,
        onClick: () => {
          notification(missingRequiredInfo)
        }
      }
    }

    return props;
  }, [enabled, isActive, hasResponse, handleClick]);

  return (
    <QuestionSelector {...questionProps}>
      {number + 1}
    </QuestionSelector>
  );
}

const QuestionSelector = styled(motion.div)<{
  isActive: boolean;
  hasResponse: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  color: #635ec0;
  background: rgba(255, 255, 255, 0.15);
  mix-blend-mode: multiply;
  border-radius: 9999px;
  font-weight: bold;
  width: 4.5rem;
  height: 4.5rem;
  cursor: pointer;
  position: relative;
  box-shadow: 0 1px 2px rgba(99, 94, 192, 0.15);
  transition: background 300ms ease, color 300ms ease;

  :hover {
    background: rgba(255, 255, 255, 0.3);
  }

  ${(props) =>
    props.hasResponse &&
    `
    background: rgba(99, 94, 192, 0.5);
    color: white;
    

    :hover {
      background: rgba(99, 94, 192, 0.75)
    }
  `}

  ${(props) =>
    props.isActive &&
    `
     background: #635ec0;
     color: white;

     :hover {
      background: #4540A4;
    }
  `}
`;

const StepFiles = styled.div`
  background: #635ec0;
  color: white;
  border-radius: 9999px;
  padding: 0.25rem 1rem;
  margin-left: 1ch;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
`;

const FormScreenHeader = styled(Stack)`
  justify-content: center;

  @media (max-width: 950px) {
    grid-auto-flow: row;
    grid-gap: 2rem;
    justify-items: flex-start;
  }
`;

const CheckmarkIcon = styled(Checkmark)`
  color: white;
  height: 2.5rem;
`;

const EditIconContainer = styled.div`
  border-radius: 9999px;
  width: 2rem;
  height: 2rem;
  padding: 4px;
  display: inline-flex;
  justify-content: center;
  margin-left: 1ch;
  align-items: center;
  background: rgba(255, 255, 255, 0.15);
  mix-blend-mode: multiply;
  cursor: pointer;
`;

const EditIcon = styled(Edit)`
  color: #635ec0;
  width: 100%;
  height: 100%;
`;
